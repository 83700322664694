import axios, { AxiosRequestConfig } from "axios";


export const Login = async (userName: string, password: string) => {
  let data = { userName: userName, password: password };

  let config: AxiosRequestConfig = {
    method: "post",
    url: "https://api.sht.distronix.in:4443/api/users/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios(config)
    .then((res) => {
      // console.log("response message-->", res.data);
      return res.data;
    })
    .catch((e) => {
      // console.log("login error-->", e.response);
      // return new Error(e.response.data.message.map((d:any)=>d.msg).join(' '))
      return Promise.reject(
        e.response.data.message.map((d: any) => d.msg).join(" ")
      );
    });

  // console.log('result data->',result.response.data)
  return result;
};
