import React, { useState, useCallback } from "react";
import Styles from "./index.module.css";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {
  Button,
  TextField,
  Select,
  Chip,
  Avatar,
  Checkbox,
  ListItemText,
  Tooltip,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { ActionAreaData, BlockData, PremiseData, ServiceData } from "./data";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import {
  faChevronCircleDown,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@material-ui/lab";
import {
  updateActionArea,
  updateBlock,
  updatePremise,
  updatePlot,
  updateQuery,
  updateService,
  reset,
} from "./FilterBar.slice";
import {
  FilterBarState,
  _ActionAreaData,
  _BlockData,
  _PlotData,
  _PremiseData,
  _ServiceData,
} from "./types";
import { RootState, TagsStaticData } from "../../redux";
import debounce from "lodash.debounce";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      flex: 2,
    },
    button: {
      margin: theme.spacing(1),
      minWidth: 50,
      flex: 1,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    chips: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

export const FaIcon = (props: FontAwesomeIconProps) => (
  <FontAwesomeIcon
    size="1x"
    className={Styles.SideBarOptionIcon}
    color="#44CCFF"
    icon={props.icon}
  />
);

export const FilterBar: React.FC<{
  refreshHook: (refresh: boolean) => void;
  toggleMarkers?: (
    toggle: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}> = ({ refreshHook, toggleMarkers }): JSX.Element => {
  const [FilterBarState, setFilterBarState] = useState<
    "COLLAPSED" | "EXPANDED"
  >("EXPANDED");
  const TagLimits = 1;
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputs: FilterBarState = useSelector(
    (state: RootState) => state.filterbar
  );
  const StaticOptions: TagsStaticData = useSelector(
    (state: RootState) => state.tags.StaticData
  );

  const [Query, setQuery] = React.useState(inputs.Query);

  const debouncedSearchConstructor = useCallback(() =>
    debounce((nextQuery: string) => {
      dispatch(updateQuery({ name: "Query", value: nextQuery.trim() }));
      refreshHook(true);
    }, 1000),
    [dispatch, refreshHook]
  );

    const debouncedSearch = debouncedSearchConstructor();

  const handleSearchQuery = (query: string) => {
    setQuery(query);
    debouncedSearch(query);
  };

  const handleChangeMultipleSelect = (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => {
    console.log({ event, value, reason, details });
    refreshHook(true);
  };

  const resetFilter = () => {
    dispatch(reset());
  };

  const toggleFilterBarState = () => {
    setFilterBarState((state) => {
      return state === "COLLAPSED" ? "EXPANDED" : "COLLAPSED";
    });
  };

  const ActionAreaOptions = StaticOptions.ActionArea.map((aa) => {
    return {
      key: aa.name,
      value: aa.name,
    };
  });

  ActionAreaOptions.unshift({
    key: "*",
    value: "Select All",
  });

  const BlockOptions = StaticOptions.ActionArea.filter(
    (aa) =>
      inputs.ActionArea.length === 0 ||
      inputs.ActionArea.findIndex((item) => item.value === aa.name) >= 0
  )
    .map((aa) =>
      aa.Block.map((b) => {
        return {
          block: b.name,
          ActionArea: aa.name,
        };
      })
    )
    .reduce((a, b) => a.concat(b), []);

  BlockOptions.unshift({
    block: "Select All",
    ActionArea: "",
  });

  // const PremiseOptions = StaticOptions.ActionArea.filter(
  //   (aa) =>
  //     inputs.ActionArea.length === 0 ||
  //     inputs.ActionArea.findIndex((item) => item.value === aa.name) >= 0
  // )
  //   .map((aa) =>
  //     aa.Block.filter(
  //       (b) =>
  //         inputs.Block.length === 0 ||
  //         inputs.Block.findIndex((item) => item.block === b.name) >= 0
  //     )
  //       .map((b) => b.tags.map((item) => item.premise))
  //       .reduce((a, b) => a.concat(b), [])
  //   )
  //   .reduce((a, b) => a.concat(b), []);

  // PremiseOptions.unshift("Select All");

  const PlotAndPremiseOptions = StaticOptions.ActionArea.filter(
    (aa) =>
      inputs.ActionArea.length === 0 ||
      inputs.ActionArea.findIndex((item) => item.value === aa.name) >= 0
  )
    .map((aa) =>
      aa.Block.filter(
        (b) =>
          inputs.Block.length === 0 ||
          inputs.Block.findIndex((item) => item.block === b.name) >= 0
      )
        .map((b) =>
          b.tags.map((item) => {
            return {
              block: b.name,
              plot: item.plot,
              premise: item.premise,
            };
          })
        )
        .reduce((a, b) => a.concat(b), [])
    )
    .reduce((a, b) => a.concat(b), []);
  PlotAndPremiseOptions.unshift({
    block: "",
    plot: "Select All",
    premise: "Select All",
  });

  const PlotOptions = PlotAndPremiseOptions.map((p) => ({
    block: p.block,
    plot: p.plot,
  }));
  const PremiseOptions = PlotAndPremiseOptions.filter((p) =>
    inputs.Plot.length === 0
      ? true
      : inputs.Plot.findIndex(
          (plot) => plot.block === p.block && plot.plot === p.plot
        ) >= 0
  ).map((p) => p.premise);
  return (
    <>
      <div
        className={`${Styles.FilterBar} ${Styles.Chips}`}
        style={{
          height: FilterBarState === "EXPANDED" ? 100 : 40,
        }}
      >
        {FilterBarState === "EXPANDED" ? (
          <>
            <div
              className={Styles.FilterLabel}
              style={{
                height: FilterBarState === "EXPANDED" ? 60 : 40,
              }}
            >
              Filter
            </div>
            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                limitTags={TagLimits}
                id="Service-Select"
                options={ServiceData}
                style={{ textOverflow: "ellipsis" }}
                getOptionLabel={(Service: { key: string; value: string }) =>
                  Service.value
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      defaultChecked={selected}
                    />
                    {option.value}
                  </React.Fragment>
                )}
                getOptionSelected={(option, value) => option.key === value.key}
                defaultValue={inputs.Service}
                value={inputs.Service}
                renderTags={(params) =>
                  params
                    .map((item) => item.value)
                    .filter((item, index) => index < TagLimits)
                    .join(", ")
                    .concat(
                      params.length > TagLimits
                        ? ` +${params.length - TagLimits} items`
                        : ""
                    )
                }
                onChange={(e, v, r, d) => {
                  handleChangeMultipleSelect(e, v, r, d);
                  dispatch(updateService({ name: "ActionArea", value: v }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Service"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                limitTags={TagLimits}
                id="Action-Area-Select"
                options={ActionAreaOptions}
                renderOption={(option, { selected }) => {
                  const id = nanoid();
                  return (
                    <>
                      <input
                        type="checkbox"
                        key={id}
                        name="actionarea"
                        defaultChecked={selected}
                      />
                      <label htmlFor={id} style={{ paddingLeft: 10 }}>
                        {option.value}
                      </label>
                    </>
                  );
                }}
                style={{ textOverflow: "ellipsis" }}
                getOptionLabel={(ActionArea: { key: string; value: string }) =>
                  ActionArea.value
                }
                getOptionSelected={(option, value) => option.key === value.key}
                defaultValue={inputs.ActionArea}
                value={inputs.ActionArea}
                renderTags={(params) =>
                  params
                    .map((item) => item.value)
                    .filter((item, index) => index < TagLimits)
                    .join(", ")
                    .concat(
                      params.length > TagLimits
                        ? ` +${params.length - TagLimits} items`
                        : ""
                    )
                }
                onChange={(e, v, r, d) => {
                  handleChangeMultipleSelect(e, v, r, d);
                  if (d?.option.key === "*") {
                    if (r === "remove-option")
                      dispatch(
                        updateActionArea({ name: "ActionArea", value: [] })
                      );
                    if (r === "select-option")
                      dispatch(
                        updateActionArea({
                          name: "ActionArea",
                          value: ActionAreaOptions,
                        })
                      );
                  } else
                    dispatch(
                      updateActionArea({ name: "ActionArea", value: v })
                    );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Action Area"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                id="Block-Select"
                // options={BlockData}
                options={BlockOptions}
                renderOption={(option, { selected }) => {
                  const id = nanoid();
                  return (
                    <>
                      <input
                        type="checkbox"
                        key={id}
                        name="block"
                        defaultChecked={selected}
                      />
                      <label htmlFor={id} style={{ paddingLeft: 10 }}>
                        {option.block}
                      </label>
                    </>
                  );
                }}
                getOptionLabel={(Block: {
                  block: string;
                  ActionArea: string;
                }) => Block.block}
                getOptionSelected={(option, value) =>
                  option.block === value.block
                }
                groupBy={(option) => option.ActionArea}
                disableCloseOnSelect={true}
                defaultValue={inputs.Block}
                value={inputs.Block}
                renderTags={(params) =>
                  params
                    .map((item) => item.block)
                    .filter((item, index) => index < TagLimits)
                    .join(", ")
                    .concat(
                      params.length > TagLimits
                        ? ` +${params.length - TagLimits} items`
                        : ""
                    )
                }
                onChange={(e, v, r, d) => {
                  handleChangeMultipleSelect(e, v, r, d);
                  dispatch(updateBlock({ name: "Block", value: v }));
                  if (d?.option.block === "Select All") {
                    if (r === "remove-option")
                      dispatch(updateBlock({ name: "Block", value: [] }));
                    if (r === "select-option")
                      dispatch(
                        updateBlock({ name: "Block", value: BlockOptions })
                      );
                  } else dispatch(updateBlock({ name: "Block", value: v }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Block"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                limitTags={TagLimits}
                id="Plot-Select"
                options={PlotOptions}
                // renderOption={(option, { selected }) => (
                //   <React.Fragment>
                //     <Checkbox
                //       icon={icon}
                //       checkedIcon={checkedIcon}
                //       style={{ marginRight: 8 }}
                //       checked={selected}
                //     />
                //     {option.block+"-"+option.plot}
                //   </React.Fragment>
                // )}
                getOptionLabel={(Plot: { block: string; plot: string }) =>
                  Plot.block !== "" ? Plot.block + "-" + Plot.plot : Plot.plot
                }
                renderOption={(option, state) => {
                  const id = nanoid();
                  return (
                    <>
                      <input
                        type="checkbox"
                        key={id}
                        name="plot"
                        defaultChecked={state.selected}
                      />
                      <label htmlFor={id} style={{ paddingLeft: 10 }}>
                        {option.block !== ""
                          ? option.block + "-" + option.plot
                          : option.plot}
                      </label>
                    </>
                  );
                }}
                groupBy={(option) => option.block}
                getOptionSelected={(option, value) => option === value}
                defaultValue={inputs.Plot}
                value={inputs.Plot}
                renderTags={(params) =>
                  params
                    .filter((item, index) => index < TagLimits)
                    .map((p) =>
                      p.block === "" ? p.plot : p.block + "-" + p.plot
                    )
                    .join(", ")
                    .concat(
                      params.length > TagLimits
                        ? ` +${params.length - TagLimits} items`
                        : ""
                    )
                }
                onChange={(e, v, r, d) => {
                  handleChangeMultipleSelect(e, v, r, d);
                  if (d?.option.plot === "Select All") {
                    if (r === "remove-option")
                      dispatch(updatePlot({ name: "Plot", value: [] }));
                    if (r === "select-option")
                      dispatch(
                        updatePlot({
                          name: "Plot",
                          value: PlotOptions,
                        })
                      );
                  } else dispatch(updatePlot({ name: "Plot", value: v }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Plot"
                    size="small"
                  ></TextField>
                )}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                limitTags={TagLimits}
                id="Premise-Select"
                options={PremiseOptions}
                style={{ textOverflow: "ellipsis" }}
                getOptionLabel={(Premise: string) => Premise}
                renderOption={(option, state) => {
                  const id = nanoid();
                  return (
                    <>
                      <input
                        type="checkbox"
                        key={id}
                        name="premise"
                        defaultChecked={state.selected}
                      />
                      <label htmlFor={id} style={{ paddingLeft: 10 }}>
                        {option}
                      </label>
                    </>
                  );
                }}
                getOptionSelected={(option, value) => option === value}
                defaultValue={inputs.Premise}
                value={inputs.Premise}
                renderTags={(params) =>
                  params
                    .filter((item, index) => index < TagLimits)
                    .join(", ")
                    .concat(
                      params.length > TagLimits
                        ? ` +${params.length - TagLimits} items`
                        : ""
                    )
                }
                onChange={(e, v, r, d) => {
                  handleChangeMultipleSelect(e, v, r, d);
                  if (d?.option === "Select All") {
                    if (r === "remove-option")
                      dispatch(updatePremise({ name: "Premise", value: [] }));
                    if (r === "select-option")
                      dispatch(
                        updatePremise({
                          name: "Premise",
                          value: PremiseOptions,
                        })
                      );
                  } else dispatch(updatePremise({ name: "Premise", value: v }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Premise"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="query-text"
                label="Search"
                value={Query}
                onChange={(e) => handleSearchQuery(e.target.value)}
              />
            </FormControl>
            <FormControl className={classes.button}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleFilterBarState()}
              >
                Apply
              </Button>
            </FormControl>
            <FormControl className={classes.button}>
              <Button variant="outlined" onClick={() => resetFilter()}>
                Reset
              </Button>
            </FormControl>
          </>
        ) : (
          <>
            <div className={classes.chips}>
              {inputs.Service.length > 0 && (
                <Tooltip
                  arrow
                  title={inputs.Service.map((service) => service.value).join(
                    ", "
                  )}
                >
                  <Chip
                    avatar={<Avatar>S</Avatar>}
                    label={
                      inputs.Service.length - TagLimits > 0
                        ? "Services: " +
                          inputs.Service[0].value +
                          " +" +
                          (inputs.Service.length - TagLimits) +
                          " items"
                        : "Services: " + inputs.Service[0].value
                    }
                  />
                </Tooltip>
              )}
              {inputs.ActionArea.length > 0 && (
                <Tooltip
                  arrow
                  title={inputs.ActionArea.map((aa) => aa.value).join(", ")}
                >
                  <Chip
                    avatar={<Avatar>A</Avatar>}
                    label={
                      inputs.ActionArea.length - TagLimits > 0
                        ? "Action Areas: " +
                          inputs.ActionArea[0].value +
                          " +" +
                          (inputs.ActionArea.length - TagLimits) +
                          " items"
                        : "Action Area: " + inputs.ActionArea[0].value
                    }
                  />
                </Tooltip>
              )}
              {inputs.Block.length > 0 && (
                <Tooltip
                  arrow
                  title={inputs.Block.map((b) => b.block).join(", ")}
                >
                  <Chip
                    avatar={<Avatar>B</Avatar>}
                    label={
                      inputs.Block.length - TagLimits > 0
                        ? "Blocks: " +
                          inputs.Block[0].block +
                          " +" +
                          (inputs.Block.length - TagLimits) +
                          " items"
                        : "Block: " + inputs.Block[0].block
                    }
                  />
                </Tooltip>
              )}

              {inputs.Plot.length > 0 && (
                <Tooltip arrow title={inputs.Plot.join(", ")}>
                  <Chip
                    avatar={<Avatar>P</Avatar>}
                    label={
                      inputs.Plot.length - TagLimits > 0
                        ? "Plots: " +
                          (inputs.Plot[0].block === ""
                            ? inputs.Plot[0].plot
                            : inputs.Plot[0].block +
                              "-" +
                              inputs.Plot[0].plot) +
                          " +" +
                          (inputs.Plot.length - TagLimits) +
                          " items"
                        : "Plot: " +
                          (inputs.Plot[0].block === ""
                            ? inputs.Plot[0].plot
                            : inputs.Plot[0].block + "-" + inputs.Plot[0].plot)
                    }
                  />
                </Tooltip>
              )}
              {inputs.Premise.length > 0 && (
                <Tooltip arrow title={inputs.Premise.join(", ")}>
                  <Chip
                    avatar={<Avatar>P</Avatar>}
                    label={
                      inputs.Premise.length - TagLimits > 0
                        ? "Premises: " +
                          inputs.Premise[0] +
                          " +" +
                          (inputs.Premise.length - TagLimits) +
                          " items"
                        : "Premise: " + inputs.Premise[0]
                    }
                  />
                </Tooltip>
              )}
              {inputs.Query.length > 0 && (
                <Tooltip arrow title={inputs.Query}>
                  <Chip
                    avatar={<Avatar>S</Avatar>}
                    label={`Search : ${
                      inputs.Query.length > 17
                        ? inputs.Query.substring(0, 16).concat("...")
                        : inputs.Query
                    }`}
                  />
                </Tooltip>
              )}
              {inputs.Service.length === 0 &&
                inputs.ActionArea.length === 0 &&
                inputs.Block.length === 0 &&
                inputs.Premise.length === 0 &&
                inputs.Plot.length === 0 &&
                inputs.Query.length === 0 && (
                  <div className={Styles.InfoText}>No Filter Applied.</div>
                )}
            </div>
              {typeof toggleMarkers !== 'undefined' &&
                <FormControlLabel
                  value="start"
                  control={<Switch color="primary" onClick={toggleMarkers} defaultChecked={true} />}
                  label="Toggle Filter"
                labelPlacement="start"
                defaultChecked={true}
                />
              }
          </>
        )}
      </div>
      <div className={Styles.CenteredButtonWrapper}>
        <div className={Styles.CenteredButton}>
          <FontAwesomeIcon
            size="2x"
            color="#666666"
            icon={
              FilterBarState === "COLLAPSED"
                ? faChevronCircleDown
                : faTimesCircle
            }
            onClick={() => toggleFilterBarState()}
          />
        </div>
      </div>
    </>
  );
};
