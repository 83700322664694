import React, { useState } from "react";
import { CardProps, VariantProps, VariantTypes } from "./types";
import styles from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Card(props: CardProps) {
  const [Displayed, setDisplayed] = useState<VariantTypes>("FRONT");

  const Front = (props: VariantProps) => {
    return (
      <div
        className={styles.Card}
        style={{
          ...props.style,
          backgroundColor: props.BackgroundColor.string(),
          color: props.FontColor.string(),
        }}
        onMouseEnter={() => setDisplayed("BACK")}
      >
        <div className={styles.CardRow}>
          <FontAwesomeIcon
            {...props.IconOptions}
            size="lg"
            color={props.FontColor.string()}
            className={styles.Icon}
            icon={props.Icon}
           
          />
          <div className={styles.Value}>{props.Value}</div>
        </div>
        <div className={styles.CardRow}>
          <div className={styles.Title}>{props.Title}</div>
        </div>
      </div>
    );
  };

  const Back = (props: VariantProps) => {
    return (
      <div
        className={styles.Card}
        style={{
          ...props.style,
          backgroundColor: props.BackgroundColor.string(),
          color: props.FontColor.string(),
        }}
        onMouseLeave={() => setDisplayed("FRONT")}
      >
        <div className={styles.CardRow}>
          <div className={styles.Title}>{props.Title}</div>
          <div className={styles.Value}>{props.Value}</div>
        </div>
        <div className={styles.CardRow}>
          <div className={styles.Description}>{props.Description}</div>
        </div>
      </div>
    );
  };
  return Displayed === "FRONT" ? (
    <Front setDisplayed={setDisplayed} {...props} />
  ) : (
    <Back setDisplayed={setDisplayed} {...props} />
  );
}
