import React from "react";
import styles from "./index.module.css";
import * as types from './types';

export function DropDown(props: types.DropDownProps) {
    return (
        <div className={styles.DropDownContainer}>
            <select name={props.name} className={styles.DropDown}>
                {props.values.map((item, index) => <option key={index} value={item.value} defaultChecked={props.defaultSelected.value === item.value?true:false}>{item.key}</option>)}
            </select>
        </div>
    )
}
