import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const RemoveWbsedcl = async (  {token,consumer_no,}:Record<'token'|'consumer_no', string>

): Promise<AxiosResponse<any>>=> {
   
  let data = {"consumer_no":consumer_no};
  const config: AxiosRequestConfig = {
    method: "post",
    url: "https://api.sht.distronix.in:4443/api/v1.2//tags/DeleteMasterWbsedcl",
    headers: {
        'Content-Type': 'application/json',
        'x-access-token':token
    },
   data:data
  };
  const response: AxiosResponse<any> = await axios(config);
  console.log(response.data)
  return response;
};