import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment";
import { templateAPI } from "../templateAPI";

export interface ITagUsageData {
  id: number;
  premise_no: string;
  isCleanedToday: boolean;
  lastCleanedAt: string;
  AgencyName: string;
}

/**
 * @async
 * @function getBinsUsageData
 * @example await getBinsUsageData()
 * @example getBinsUsageData.then(r => {
 *  //do something
 * }).catch(e => {
 *  //handle error
 * })
 * @return {PromiseLike<ITagUsageData[]>}
 *  returns a promise with tag data.
 */
export const getBinsUsageData = async (
  {token,QueryDate}:Record<'token'|'QueryDate', string>
 
  ): Promise<AxiosResponse<any>> => {
  var config: AxiosRequestConfig = {
    method: "get",
    url: "https://api.sht.distronix.in:4443/api/v1.1/tags/getAllTags" + "?date="+QueryDate,
    headers: {
      'x-access-token': token ,
    },
  };
  const response: AxiosResponse<any> = await axios(config);
  console.log("GetBins=== ", response.data)
  return response;
};

