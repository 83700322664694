import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const GetPremiseWise = async ({
  token,
  startDate,
  endDate,
  Premises,
}: Record<"token" | "startDate" | "endDate" | "Premises", string>): Promise<AxiosResponse<any>> => {
  var data = {
    start_date: startDate,
    end_date: endDate,
    premises: Premises,
  };

  var config: AxiosRequestConfig = {
    method: "post",
    url: "https://api.sht.distronix.in:4443/api/reports/premises/dailyCleanTime",
    headers: {
      "x-access-token": token,
    },
    data: data,
  };

  // @ts-ignore
  const response: AxiosResponse<any> = await axios(config).catch(function (error) {
    console.log(error);
  });

  return response;
};
