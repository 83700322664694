import { FunctionComponent } from "react";

import {
  Dashboard,
  MapView,
  MasterDataReport,
  ActionAreaBinsDataReport,
  BlockWiseBinsDataReport,
  BinsCleanedDataReport,
  BinsUncleanedDataReport,
  VtsBinCheckReport,
  TotalReport,
  PremiseWise,
} from "../../pages";
import { Electricity } from "../../pages/Reports/electricity";
import { RegularScannedReport } from "../../pages/Reports/regularScannedReport";

interface UserRoute {
  Component: FunctionComponent;
  path: string;
  title: string;
}

export const UserRoutes: UserRoute[] = [
  {
    Component: Dashboard,
    path: "/dashboard",
    title: "Dashboard",
  },
  {
    Component: MapView,
    path: "/map",
    title: "Map View",
  },
  {
    Component: MasterDataReport,
    path: "/reports/master-data",
    title: "Master Data Report",
  },
  {
    Component: ActionAreaBinsDataReport,
    path: "/reports/actionarea-wise-bins",
    title: "ActionArea wise Bins Data",
  },
  {
    Component: PremiseWise,
    path: "/reports/premise-wise",
    title: "Premise Wise Report",
  },
  {
    Component: BlockWiseBinsDataReport,
    path: "/reports/block-wise-bins",
    title: "Block wise Bins Data",
  },
  {
    Component: BinsCleanedDataReport,
    path: "/reports/bins-cleaned",
    title: "Cleaned Bins Data",
  },
  {
    Component: BinsUncleanedDataReport,
    path: "/reports/bins-uncleaned",
    title: "Uncleaned Bins Data",
  },
  {
    Component: VtsBinCheckReport,
    path: "/reports/vts-bin-check",
    title: "VTS BIN CHECK",
  },
  {
    Component: TotalReport,
    path: "/reports/total-reports",
    title: "Total ",
  },
  {
    Component: RegularScannedReport,
    path: "/reports/regular-reports",
    title: "Regular",
  },
  {
    Component: Electricity,
    path: "/reports/electricity",
    title: "Electricity",
  },
];
