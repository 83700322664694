import React, { useCallback, useState } from "react";
import Styles from "./styles.module.css";
import GoogleMapReact from "google-map-react";
import { useSelector } from "react-redux";
import {
  AuthenticateUserByIdPassword,
  RootState,
  useAppDispatch,
  getAndStoreTags,
} from "../../redux";
import { Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnyAction } from "@reduxjs/toolkit";
import { GetOtp } from "../../api/login/getOtp";
import { ForgetPassword } from "../../api/login/forgetPassword";
import crypto from "crypto";
import { Form, Input, Button, Checkbox } from "antd";

export const SignIn = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [UserId, setUserId] = useState("");
  const [Password, setPassword] = useState("");
  const [Pdsecret, setPdsecret] = useState("");
  const [forgetPasssword, setForgetPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [getOtp, setGetOtp] = useState(false);
  const dispatch = useAppDispatch();
  const { loggedIn, loggingIn } = useSelector((state: RootState) => state.user);
  const center = {
    lat: 22,
    lng: 88,
  };
  const zoom = 11;

  const tryToLogin = useCallback(async () => {
    const algorithm = "aes-256-cbc";

    // the cipher function
    const cipher = crypto.createCipheriv(
      algorithm,
      "12345678123456781234567812345678",
      "1234567812345678"
    );

    // encrypt the message
    // input encoding
    // output encoding
    // @ts-ignore
    let encryptedData = cipher.update(Password, "utf-8", "hex");
    // @ts-ignore
    encryptedData += cipher.final("hex");

    dispatch<any>(
      AuthenticateUserByIdPassword({ UserId, Password: encryptedData })
    )
      .then((res: AnyAction) => {
        console.log(res);
        if (res.payload) {
          enqueueSnackbar("Logged In", { variant: "success" });
        } else
          enqueueSnackbar("Failed to log in, Check your username/password", {
            variant: "error",
          });
      })
      .catch((ex: any) => {
        console.log(ex);
        enqueueSnackbar("Failed to log in, Check your username/password.", {
          variant: "error",
        });
      });
  }, [UserId, Password, dispatch, enqueueSnackbar]);

  const generateOtp = async () => {
    //generateOtpApi
    const data = await GetOtp(UserId);
    // console.log(data)
    enqueueSnackbar("Please Enter Otp from your mail", { variant: "success" });
    setForgetPassword(false);
    setGetOtp(true);
  };
  const submitOtp = async () => {
    const algorithm = "aes-256-cbc";

    // the cipher function
    const cipher = crypto.createCipheriv(
      algorithm,
      "12345678123456781234567812345678",
      "1234567812345678"
    );
    if (Password === confirmPassword) {
      // encrypt the message
      // input encoding
      // output encoding
      // @ts-ignore
      let encryptedData = cipher.update(Password, "utf-8", "hex");
      // @ts-ignore
      encryptedData += cipher.final("hex");

      const data = await ForgetPassword(
        UserId,
        encryptedData,
        encryptedData,
        otp
      );
      enqueueSnackbar("Sucessfully Password Changed", { variant: "success" });
      setPassword("");
      setGetOtp(false);
    } else {
      enqueueSnackbar("Password and Confirm Password must be same!!", {
        variant: "error",
      });
    }
  };

  const handleClick = () => {
    // enqueueSnackbar("Trying to log in", { variant: 'info' });
    if (forgetPasssword) {
      generateOtp();
      return;
    }
    if (getOtp) {
      submitOtp();
      return;
    }
    tryToLogin();
  };
  const handleForgetPasswordClick = () => {
    setForgetPassword(true);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  const AnyReactComponent = ({ text }: any): any => (
    <FontAwesomeIcon size="lg" color="#33ccee" icon={faMapMarker} />
  );

  if (loggedIn) return <Redirect to="/user/dashboard" />;
  return (
    <div className={Styles.container}>
      <div className={Styles.SignBox}>
        <div className={Styles.SignBoxBackground} onKeyPress={handleKeyPress}>
          <input
            type="text"
            className={Styles.input_box}
            placeholder="User Id"
            value={UserId}
            autoComplete="new-name"
            onChange={(e) => setUserId(e.target.value)}
            // onKeyPress={(e) => console.log(e)}
          />
          {!forgetPasssword && (
            <>
              <input
                type="text"
                className={[Styles.input_box, Styles.pass].join(" ")}
                placeholder="Password"
                value={Password}
                autoComplete="off"
                onSelect={() => {}}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                style={{
                  color: "#000",
                  position: "absolute",
                  left: 60,
                  marginTop: -60,
                }}
              >
                {Password.split("")
                  .map((x) => "*")
                  .join("")}
              </div>
            </>
          )}
          {!!getOtp && (
            <>
              <input
                type="password"
                className={Styles.input_box}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete="new-password"
                // onKeyPress={(e) => console.log(e)}
              />
              <input
                type="text"
                className={Styles.input_box}
                placeholder="Otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                // onKeyPress={(e) => console.log(e)}
              />
            </>
          )}
          {/* <input
            type="password"
            className={Styles.input_box}
            placeholder="Password"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
            // onKeyPress={(e) => console.log(e)}
          /> */}
          {/* <div className={Styles.CheckBoxText}>
            <input className={Styles.Checkbox} type="checkbox" />
            Remember Me
          </div> */}

          <button className={Styles.loginButton} onClick={() => handleClick()}>
            {/* {!forgetPasssword ? 'Submit':'Generate Otp'}  */}

            {!forgetPasssword && !getOtp && "Login"}
            {!!forgetPasssword && "Request Otp"}
            {!!getOtp && "Submit"}
          </button>

          {!forgetPasssword && !getOtp && (
            <p
              style={{
                color: "red",
                fontWeight: "bold",
                marginLeft: "40px",
                cursor: "pointer",
              }}
              onClick={handleForgetPasswordClick}
            >
              Forget Password
            </p>
          )}
        </div>
      </div>
      {/* <div style={{ flex: 1 }}> */}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyBEXQ990KC3e-1GklhfLENNavc40uRLvKk",
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={{
          backgroundColor: "#cccccc",
        }}
      >
        <AnyReactComponent lat={22} lng={88} text="My Marker" />
      </GoogleMapReact>
      {/* </div> */}
    </div>
  );
};
