import React from "react";
import { Header } from "../../components";
// import { useSelector } from "react-redux";
// import { RootState } from "../../redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { GuestRoutes } from "./route";

export const GuestLayout = () => {
  const LayoutPath = "/guest";
  // const flag = useSelector((state: RootState) => state.user.loggedIn);
  // console.log({flag})
  const getRoutes = () => {
    return GuestRoutes.map((item, key) => (
      <Route
        path={LayoutPath + item.path}
        key={key}
      >
        <item.Component />
      </Route>
    ));
  };
  
  return (
    <div
      className="App"
      style={{
        flex: 1,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header name="NKDA Smart Home Tags" logoURL="/nkda-logo.png" nkgsccl_logoURL="/nkgsccl-logo.png" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
        }}
      >
        <Switch>
          {getRoutes()}
          <Route path="/">
              <Redirect to="/guest/signin" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
