import {
  faCheckCircle,
  faCircleNotch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { UpdateTags } from "../../api/tags/updateTags";
// import useStyles from './styles';
import { Form, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "@material-ui/core";
import { callAPI } from "../../api/callAPI";
import { RootState, useAppDispatch } from "../../redux";
import { useSelector } from "react-redux";
import { UpdateTagPic } from "../../api/tags/updateTagPic";
export const ShowTagDetails: React.FC<{
  closeView: () => void;
  data: any;
}> = ({ closeView = () => {}, data }) => {
  const initialState = "";
  const [premiseNo, setPremiseNo] = useState<string>(data.premise_no);
  // const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
  const [ImageURL, setImageURL] = useState<any>(data.photo_url);
  const [Block, setBlock] = useState(data.block);
  const [Plot, setPlot] = useState(data.plot_no);
  const [AplicationName, setAplicationName] = useState(data.application_name);
  const [ProjectName, setProjectName] = useState(data.project_name);
  const [ActionArea, setActionArea] = useState(data.action_area);
  const [Latitude, setLatitude] = useState(data.latitude)
  const [Longitude, setLongitude] = useState(data.longitude)
  const [imageFile,setImageFile] = useState(null)
  const dispatch = useAppDispatch()
  const {token,refreshToken,userRole} = useSelector((state:RootState) => state.user)


  const handleChange = (e:any) => {
    setImageFile(e.target.files[0])
    console.log(e.target.files[0])
}

  const closeFormAfterInsertion = async (e:any) => {
    e.preventDefault();
    const rows:any = await callAPI<any>(
      UpdateTags,
      { token: token, 
        premise_no: premiseNo,  
        Plot: Plot,
        Block: Block,
        AplicationName: AplicationName,
        ProjectName: ProjectName,
        ActionArea: ActionArea,
        Latitude: Latitude,
        Longitude: Longitude 
      },
      refreshToken,
      dispatch
    );
    if(!!imageFile){
    const data2 = await callAPI<any>(
      UpdateTagPic,
      { token: token,
        //@ts-ignore
        imageFile:imageFile,
        premise_no: data.premise_no
        },
      refreshToken,
      dispatch
    );
      }
    // console.log(data2);

    closeView();
  };

  return (

    <>
    <div style={{marginLeft:'30px',display:'flex',}}>
      
<Form>

<Form.Row style={{ display: "flex",marginTop:'10px' }}>
  <Form.Group as={Col} >
    <Form.Label
      style={{
        fontSize: "16",
        color: "grey",
        fontWeight: "bold",
        fontFamily: "sans-serif",
      }}
    >
      Plot No
    </Form.Label>
    <Form.Control
      style={{ width: 250, marginRight:'20px' }}
      placeholder="Plot No"
      value={Plot}
      onChange={(e) => setPlot(e.target.value)}
    />
  </Form.Group>

  <Form.Group as={Col} >
    <Form.Label
      style={{
        fontSize: "16",
        color: "grey",
        fontWeight: "bold",
        fontFamily: "sans-serif",
      }}
    >
      Block
    </Form.Label>
    <Form.Control
      style={{ width: 250 }}
      placeholder="Block"
      value={Block}
      onChange={(e) => setBlock(e.target.value)}
    />
  </Form.Group>
</Form.Row>

<Form.Row style={{ display: "flex",marginTop:'10px'  }}>
  <Form.Group as={Col} >
    <Form.Label
      style={{
        fontSize: "16",
        color: "grey",
        fontWeight: "bold",
        fontFamily: "sans-serif",
      }}
    >
      Project Name
    </Form.Label>
    <Form.Control
      style={{ width: 250 }}
      placeholder="Project Name"
      value={ProjectName}
      onChange={(e) => setProjectName(e.target.value)}
    />
  </Form.Group>

  <Form.Group as={Col}>
    <Form.Label
      style={{
        fontSize: "16",
        color: "grey",
        fontWeight: "bold",
        fontFamily: "sans-serif",
      }}
    >
      Application Name
    </Form.Label>
    <Form.Control
      style={{ width: 250 }}
      placeholder="Application Name"
      value={AplicationName}
      onChange={(e) => setAplicationName(e.target.value)}
    />
  </Form.Group>
</Form.Row>
<Form.Row style={{marginTop:'10px' }}>
<Form.Group as={Col} >
    <Form.Label
      style={{
        fontSize: "16",
        color: "grey",
        fontWeight: "bold",
        fontFamily: "sans-serif",
      }}
    >
      Action Area
    </Form.Label>
    <Form.Control
      style={{ borderWidth: 1, borderColor: "lightgrey", width: 250 }}
      as="select"
      placeholder="Action Area"
      value={ActionArea}
      onChange={(e) => setActionArea(e.target.value)}
    >
      <option disabled value="">
        Please Select
      </option>
      <option value="AA-1">AA-1</option>
      <option value="AA-2">AA-2</option>
      <option value="AA-3">AA-3</option>
    </Form.Control>
  </Form.Group>
</Form.Row>

<Form.Row style={{ display: "flex",marginTop:'10px'  }}>
 
  <Form.Group as={Col}>
    <Form.Label
      style={{
        fontSize: "16",
        color: "grey",
        fontWeight: "bold",
        fontFamily: "sans-serif",
      }}
    >
      Latitude
    </Form.Label>
    <Form.Control
      style={{ width: 250 }}
      placeholder="Latitude"
      value={Latitude}
      onChange={(e) => setLatitude(e.target.value)}
      type='number'
    />
  </Form.Group>

  <Form.Group as={Col} controlId="formGridCity">
    <Form.Label
      style={{
        fontSize: "16",
        color: "grey",
        fontWeight: "bold",
        fontFamily: "sans-serif",
      }}
    >
      Longitude
    </Form.Label>
    <Form.Control
      style={{ width: 250 }}
      placeholder="Longitude"
      value={Longitude}
      onChange={(e) => setLongitude(e.target.value)}
      type='number'
    />
  </Form.Group>
</Form.Row>
</Form>
      </div>
      <label htmlFor="image" style={{marginLeft:'10%'}}>Update Tag Image</label>
      <input
        accept="image/*"
        id="image"
        name="image"
        type="file"
        onChange={handleChange}
        style={{ marginTop: "20px", marginLeft: "3%" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "2%",
        }}
      >
        <button className="btn btn-primary" onClick={closeFormAfterInsertion}  disabled={userRole!=='admin'?true:false}>
          Update & Close
        </button>

        <button className="btn btn-danger" onClick={closeView}>
          Close
        </button>
      </div>
      </>
  );
};
