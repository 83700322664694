import { Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

export const ReportMenu: React.FC<{
  ReportEl: null | HTMLElement;
  id: string;
  handleClose: () => void;
  handleClick: () => void;
}> = ({ ReportEl, id, handleClose, handleClick }) => {
  return (
    <Menu
      id={id}
      anchorEl={ReportEl}
      keepMounted
      open={Boolean(ReportEl)}
      onClose={handleClose}
      style={{ top: 30 }}
    >
      <Link to="/user/reports/master-data">
        <MenuItem
          onClick={() => {
            handleClick();
            handleClose();
          }}
        >
          Master Data
        </MenuItem>
      </Link>
      <Link to="/user/reports/actionarea-wise-bins">
        <MenuItem
          onClick={() => {
            handleClick();
            handleClose();
          }}
        >
          ActionArea-wise Bins Data
        </MenuItem>
      </Link>
      <Link to="/user/reports/block-wise-bins">
        <MenuItem
          onClick={() => {
            handleClick();
            handleClose();
          }}
        >
          Block-wise Bins Data
        </MenuItem>
      </Link>
      <Link to="/user/reports/bins-cleaned">
        <MenuItem
          onClick={() => {
            handleClick();
            handleClose();
          }}
        >
          Cleaned Bins Data
        </MenuItem>
      </Link>
      <Link to="/user/reports/bins-uncleaned">
        <MenuItem
          onClick={() => {
            handleClick();
            handleClose();
          }}
        >
          Uncleaned Bins Data
        </MenuItem>
      </Link>
    </Menu>
  );
};
