import React from "react";
import styles from "./index.module.css";
import * as types from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { logout, RootState, store } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../api/login/logout";
import axios, { AxiosRequestConfig } from "axios";

export function Header(props: types.HeaderProps) {
  const dispatch = useDispatch();
  const flag = useSelector((state: RootState) => state.user.loggedIn);
  const refreshToken = useSelector((state: RootState) => state.user.refreshToken);
  
  
  const logoutUser = ()=>{

 
    console.log(refreshToken)

 let config:AxiosRequestConfig = {
  method: 'post',
  url: 'https://api.sht.distronix.in:4443/api/users/logout',
  headers: { 
  
    'x-access-refresh-token': refreshToken
  },
 
};

axios(config)
.then((response) => {
  console.log(response.data);

})
.catch((error) => {
  console.log(error.message);
});
  }

  const handleLogoutButton = () => {
    dispatch(logout());
    Logout(store.dispatch,refreshToken)
  };
  return (
    <div className={styles.HeaderWrapper}>
      <div className={styles.NameAndLogo}>
        <img
          className={styles.Logo}
          src={props.logoURL}
          alt={props.name + " logo"}
        ></img>
        <img
          className={styles.NKGSCCL_Logo}
          src={props.nkgsccl_logoURL}
          alt={"NKGSCCL logo"}
        ></img>
      </div>
      <div className={styles.Brand}>{props.name}</div>
      {flag && (
        <div className={styles.NotificationBar}>
          <div
            className={styles.NotificationBell}
            onClick={() => handleLogoutButton()}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.SideBarOptionIcon}
              color="#CCCCCC"
              icon={faSignOutAlt}
            />
            <b style={{ color: "#CCCCCC", fontSize: 12 }}>Logout</b>
          </div>
        </div>
      )}
    </div>
  );
}
