import * as types from './types';

export const ActionAreaData: types._ActionAreaData = [
  {
    key: '1',
    value: "I",
  },
  {
    key: '2',
    value: "II",
  },
  {
    key: '3',
    value: "III",
  },
];

export const BlockData: types._BlockData = [
  {
    block: "AA",
    ActionArea: "II",
  },
  {
    block: "AB",
    ActionArea: "I",
  },
  {
    block: "AC",
    ActionArea: "I",
  },
  {
    block: "BA",
    ActionArea: "I",
  },
  {
    block: "BF",
    ActionArea: "I",
  },
  {
    block: "BG",
    ActionArea: "I",
  },
  {
    block: "CC",
    ActionArea: "I",
  },
  {
    block: "FE",
    ActionArea: "I",
  },
  {
    block: "GF",
    ActionArea: "I",
  },
  {
    block: "FD",
    ActionArea: "II",
  },
  {
    block: "AK",
    ActionArea: "I",
  },
  {
    block: "BB",
    ActionArea: "II",
  },
  {
    block: "BC",
    ActionArea: "II",
  },
  {
    block: "BN",
    ActionArea: "II",
  },
  {
    block: "EE",
    ActionArea: "II",
  },
  {
    block: "EC",
    ActionArea: "II",
  },
  {
    block: "BK",
    ActionArea: "II",
  },
  {
    block: "CN",
    ActionArea: "II",
  },
];

export const ServiceData: types._ServiceData = [
  {
    key: "tag",
    value: "Tag",
  },
  {
    key: "swm",
    value: "SWM",
  },
  {
    key: "wbsedcl",
    value: "WBSEDCL",
  },
  {
    key: "vts",
    value: "VTS",
  },
  // {
  //   key: "mosquito",
  //   value: "Mosquito",
  // },
  // {
  //   key: "tax",
  //   value: "Tax",
  // },
];

// export const PlotData: types._PlotData = [
//     "AL-26", "AL-23", "BK-14", "FE-456", "FE-495"
// ];

export const PremiseData: types._PremiseData = ["ABD - QW, Asdf", "ABC - QW, Asdf","ASCD - QW, Asdf","ABCD - QW, DSFsdf","ABCD - QP, Asdf","ABCD - QW, AASDX","ABCD - QW, Askjf"];