import { FunctionComponent } from "react";

import { SignIn } from "../../pages";

interface GuestRoute {
  Component: FunctionComponent;
  path: string;
  title: string;
}

export const GuestRoutes: GuestRoute[] = [
  {
    Component: SignIn,
    path: "/signin",
    title: "Sign In",
  },
];
