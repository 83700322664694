import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as types from "../../index";
import {  getStatisticalKPIs, IGetStatisticalKPIs } from "../../../api";
import moment from "moment";
import { callAPI } from "../../../api/callAPI";
import { store } from "../../index";
import { AxiosResponse } from "axios";

interface DateRange {
  start_date: moment.Moment;
  end_date: moment.Moment;
}

export const getAndStoreTodaysKPIs = createAsyncThunk(
  "KPI/getAndStoreTodaysKPIs",
  async (thunkAPI) => {
    const user = store.getState().user;
    const result: AxiosResponse<IGetStatisticalKPIs> | null = await callAPI<IGetStatisticalKPIs>(getStatisticalKPIs, {
      token: user.token,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    },
      user.refreshToken,
      store.dispatch
    );
    const response = result === null ? {}:result.data.kpi
    return response;
  }
);
export const getAndStoreDateRangeKPIs = createAsyncThunk(
  "KPI/getAndStoreDateRangeKPIs",
  async (range: DateRange, thunkAPI) => {
    const user = store.getState().user;
    const result: AxiosResponse<IGetStatisticalKPIs> | null =
      await callAPI<IGetStatisticalKPIs>(
        getStatisticalKPIs,
        {
          token: user.token,
          start_date: range.start_date.format("YYYY-MM-DD"),
          end_date: range.end_date.format("YYYY-MM-DD"),
        },
        user.refreshToken,
        store.dispatch
      );
    const response = result === null ? {} : result.data.kpi;
    return response;
  }
);

const initialState: types.KPIState = {
    loading: false,
    loaded: false,
    kpi: {},
    SelectedDateRange: {}
};

export const KPISlice = createSlice({
  name: "KPI",
  initialState,
  reducers: {
    replace_all: (
      state: types.KPIState,
      action: PayloadAction<types.KPIState>
    ): types.KPIState => {
      return action.payload;
    },
  },
  extraReducers: {
    [getAndStoreTodaysKPIs.fulfilled.type]: (
      state: types.KPIState,
      action: PayloadAction<{ [key: string]: number }>
    ) => {
      state.kpi = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    [getAndStoreTodaysKPIs.rejected.type]: (
      state: types.KPIState,
      action: PayloadAction<any>
    ) => {
      state.loaded = false;
      state.loading = false;
    },
    [getAndStoreTodaysKPIs.pending.type]: (
      state: types.KPIState,
      action: PayloadAction
    ) => {
      state.loaded = false;
      state.loading = true;
    },
    [getAndStoreDateRangeKPIs.fulfilled.type]: (
      state: types.KPIState,
      action: PayloadAction<{ [key: string]: number }>
    ) => {
      state.SelectedDateRange = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    [getAndStoreDateRangeKPIs.rejected.type]: (
      state: types.KPIState,
      action: PayloadAction<any>
    ) => {
      state.loaded = false;
      state.loading = false;
    },
    [getAndStoreDateRangeKPIs.pending.type]: (
      state: types.KPIState,
      action: PayloadAction
    ) => {
      state.loaded = false;
      state.loading = true;
    },
  },
});
