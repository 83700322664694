import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState, useAppDispatch } from '../../redux';
import { callAPI } from '../callAPI';
import { templateAPI } from '../templateAPI';

export interface IDateRangeWiseBinsData {
  ActionArea: string;
  TotalBins: number;
  CleanedBins: number;
  UncleanedBins: number;
  Blocks: {
    name: string;
    TotalBins: number;
    CleanedBins: number;
    UncleanedBins: number;
  }[];
}

export interface IActionAreaWiseBinsData {
  id: string;
  ActionArea: string;
  TotalBins: number;
  CleanedBins: number;
  UncleanedBins: number;
  CleanedBinsPercentage: number;
  UncleanedBinsPercentage: number;
  Date: string;
}

export interface IBlockWiseBinsData {
  id: string;
  Block: string;
  ActionArea: string;
  TotalBins: number;
  CleanedBins: number;
  UncleanedBins: number;
  CleanedBinsPercentage: number;
  UncleanedBinsPercentage: number;
  Date: string;
}

export const getDatewiseBinsData = async (  {token,startDate,endDate}:Record<'token'|'startDate'|'endDate', any>
 
): Promise<AxiosResponse<any>> => {
    const params = `start_date=${startDate.format(
      "YYYY-MM-DD"
    )}&end_date=${endDate.format("YYYY-MM-DD")}`;
    const config: AxiosRequestConfig = {
      method: "get",
      url: "https://api.sht.distronix.in:4443/api/v1.1/statistics/binsDateAvg?"+params,
      headers: {
        'x-access-token':token
      },
    };
    
    const response: AxiosResponse<any> = await axios(config);
    // console.log("GetAllVtsBin: ", response.data)
    return response;
}
export const getBinsData = async ( {token,}:Record<'token', string>
 
): Promise<AxiosResponse<any>> => {
    const params = `start_date=${moment().format(
      "YYYY-MM-DD"
    )} 00:00:00&end_date=${moment().format("YYYY-MM-DD")} 23:59:59`;
    const config: AxiosRequestConfig = {
      method: "get",
      url: "https://api.sht.distronix.in:4443/api/v1.1/statistics/bins?"+params,
      headers: {
        'x-access-token':token
      },
    };
    
    const response: AxiosResponse<any> = await axios(config);
    // console.log("GetAllVtsBin: ", response.data)
    return response;
}
export const getDatewiseBinsData2 = async ({token,startDate,endDate}:Record<'token'|'startDate'|'endDate', string>): Promise<AxiosResponse<any>> => {

  const config: AxiosRequestConfig = {
    method: "get",
    url: "https://api.sht.distronix.in:4443/api/v1.1/statistics/binsDate",
    headers: {'x-access-token': token ,},
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  };

  const response: AxiosResponse<any> = await axios(config);
  console.log("bins data-->: ", response.data.bins)
  return response;
  // const result: any = await templateAPI(config)
  //   .then((r) => r.data)
  //   .then(r => r.bins)
  //   .catch((e) => {
  //     console.log(e);
  //     return [];
  //   });
  
  //   const newData = Object.keys(result).map((k:any)=>{
  //     const d =  result[k].map((item:any,index:any)=>({
  //        ...item,
  //        date: k
  //      }))
  //      return d
  //    }).reduce((pre:any,next:any)=>([...pre,...next]))


    //  .sort((pre:any,next:any)=>{
      
    //   return (pre.ActionArea) === (next.ActionArea)? 0:
    //     ((pre.ActionArea) > (next.ActionArea)? 1: -1)

    //  })
   
   
    
    // return newData;
   
}

export const GetActionAreaWiseBinsData = async (  token:string,
  refreshToken:string,
  startDate: string,
  endDate: string,
  dispatch:AppDispatch) => {
  
  // const data = await getDatewiseBinsData2(start, end);
 
  const binsData = await callAPI<any>(
    getDatewiseBinsData2,
    { token: token, startDate: startDate, endDate: endDate },
    refreshToken,
    dispatch
  );
  console.log('binsss-->',binsData?.data.bins)

    const newData = Object.keys(binsData?.data.bins).map((k:any)=>{
        const d =  binsData?.data.bins[k].map((item:any,index:any)=>({
           ...item,
           date: k
         }))
         return d
       }).reduce((pre:any,next:any)=>([...pre,...next]))

     const actionareawisedata = newData.map((d:any,index:number) => {
   
    const newData: any = {
      id: index+1,
      ActionArea: d.ActionArea,
      TotalBins: d.TotalBins,
      CleanedBins: d.CleanedBins,
      UncleanedBins: d.UncleanedBins,
      CleanedBinsPercentage:
        Math.floor((d.CleanedBins / d.TotalBins) * 10000) / 100,
      UncleanedBinsPercentage:
        Math.floor((d.UncleanedBins / d.TotalBins) * 10000) / 100,
        Date:d.date
    
    };
    return newData;
  });
  return actionareawisedata;
}

export const GetBlockWiseBinsData = async (
  token:string,
  refreshToken:string,
  startDate: string,
  endDate: string,
  dispatch:AppDispatch
) => {
  // const data = await getDatewiseBinsData2(start, end);

  
  const binsData = await callAPI<any>(
    getDatewiseBinsData2,
    { token: token, startDate: startDate, endDate: endDate },
    refreshToken,
    dispatch
  );
  console.log('binsss2-->',binsData?.data.bins)
    
  const newData = Object.keys(binsData?.data.bins).map((k:any)=>{
    const d =  binsData?.data.bins[k].map((item:any,index:any)=>({
       ...item,
       date: k
     }))
     return d
   }).reduce((pre:any,next:any)=>([...pre,...next]))

  const blockdata = newData?.map((i:any) => i.Blocks
  .map((b:any) => ({ ...b, ActionArea: i.ActionArea,Date:i.date })))
  .reduce((p:any, n:any) => ([ ...p, ...n ]));


  const blockwisedata = blockdata.map((d:any,index:number) => {
    const newData: any = {
      id: index+1,
      ActionArea: d.ActionArea,
      Block: d.name,
      TotalBins: d.TotalBins,
      CleanedBins: d.CleanedBins,
      UncleanedBins: d.UncleanedBins,
      CleanedBinsPercentage:
        Math.floor((d.CleanedBins / d.TotalBins) * 10000) / 100,
      UncleanedBinsPercentage:
        Math.floor((d.UncleanedBins / d.TotalBins) * 10000) / 100,
        Date:d.Date
 
    };
    return newData;
  });
  return blockwisedata;
};