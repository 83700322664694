import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { store, UserState } from "../../index";
import { getFreshToken } from "../../../Util";
import { Login } from "../../../api/login/login";
import { Logout } from "../../../api/login/logout";

export interface loginCredentials { UserId: string; Password: string}

export const AuthenticateUserByIdPassword = createAsyncThunk(
  "users/AuthenticatingUserByIdPassword",
  async (user: loginCredentials, thunkAPI) => {
    const response = await Login(user.UserId, user.Password);
    console.log('response',response)
    return response;
  }
);

export const SignOutUser = createAsyncThunk(
  "users/SigningOutUser",
  async (user: loginCredentials, thunkAPI) => {
    const response = await Logout(store.dispatch,store.getState().user.refreshToken);
    return response;
  }
);

const initialState: UserState = {
  loggedIn: false,
  loggingIn: false,
  name: "",
  userName: "",
  userRole: "",
  profilePic: "",
  token: "",
  refreshToken: "",
};

interface loginPayload {
  name: string;
  userName: string;
  userRole: string;
  profilePic: string;
  token: string;
  refreshToken: string;
}

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (
      state: UserState,
      action: PayloadAction<loginPayload>
    ): UserState => {
      return { ...state, loggedIn: true, ...action.payload };
    },
    logout: (_state: UserState) => {
      return { ...initialState, loggedIn: false };
    },
    updateAccessToken: (state: UserState,
      action: PayloadAction<string>): UserState => {
      return {...state, token: action.payload} 
      }
  },
  extraReducers: {
    [AuthenticateUserByIdPassword.fulfilled.type]: (
      state,
      action: PayloadAction<loginPayload>
    ) => {
      console.log("Action Payload: ", action.payload)
      state.loggedIn = true;
      state.loggingIn = false;
      state.name = action.payload.name;
      state.profilePic = action.payload.profilePic;
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
      state.userName = action.payload.userName;
      state.userRole = action.payload.userRole;
    },
    [AuthenticateUserByIdPassword.pending.type]: (state, action) => {
      state.loggingIn = true;
    },
    [AuthenticateUserByIdPassword.rejected.type]: (state, action) => {
      state.loggingIn = false;
      state.loggedIn = false;
    },
  },
});

export const { login, logout, updateAccessToken } = UserSlice.actions;
