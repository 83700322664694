import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as types from "./types";
import { store, Tag } from '../../redux';

const initialState: types.FilterBarState = {
  ActionArea: [],
  Block: [],
  Plot: [],
  Premise: [],
  Query: "",
  Service: [],
};

export const FilterBarSlice = createSlice({
  name: "filterbar",
  initialState,
  reducers: {
    reset: (state: types.FilterBarState, action: PayloadAction<void>) => {
      return initialState;
    },
    updateActionArea: (
      state: types.FilterBarState,
      action: PayloadAction<{
        name: types.FilterBarOptions;
        value: types._ActionAreaData;
        reason?: any;
      }>
    ) => {
      return {
        ...state,
        ActionArea: !!action.payload.value ? action.payload.value : [],
      };
    },
    updateBlock: (
      state: types.FilterBarState,
      action: PayloadAction<{
        name: types.FilterBarOptions;
        value: types._BlockData;
      }>
    ) => {
      return {
        ...state,
        Block: !!action.payload.value ? action.payload.value : [],
      };
    },
    updatePlot: (
      state: types.FilterBarState,
      action: PayloadAction<{
        name: types.FilterBarOptions;
        value: types._PlotData;
      }>
    ) => {
      return {
        ...state,
        Plot: !!action.payload.value ? action.payload.value : [],
      };
    },
    updatePremise: (
      state: types.FilterBarState,
      action: PayloadAction<{
        name: types.FilterBarOptions;
        value: types._PremiseData;
      }>
    ) => {
      return {
        ...state,
        Premise: !!action.payload.value ? action.payload.value : [],
      };
    },
    updateService: (
      state: types.FilterBarState,
      action: PayloadAction<{
        name: types.FilterBarOptions;
        value: types._ServiceData;
      }>
    ) => {
      return {
        ...state,
        Service: !!action.payload.value ? action.payload.value : [],
      };
    },
    updateQuery: (
      state: types.FilterBarState,
      action: PayloadAction<{
        name: types.FilterBarOptions;
        value: string;
      }>
    ) => {
      return {
        ...state,
        Query: !!action.payload.value ? action.payload.value : "",
      };
    },
  },
});

export const { reset, updateActionArea, updateBlock, updatePlot, updatePremise, updateQuery, updateService } = FilterBarSlice.actions;
export * from './types';