import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";

export const getWeeklyData = async ( {token,startDate,endDate}:Record<'token'|'startDate'|'endDate', string>): Promise<AxiosResponse<any>> => {
    var config: AxiosRequestConfig = {
      method: "get",
      url: "https://api.sht.distronix.in:4443/api/reports/weeklyReport",
      headers: {'x-access-token': token},
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    };
  
  
  const response: AxiosResponse<any> = await axios(config);
  console.log("GetAllBin: ", response.data)
  return response;
  };