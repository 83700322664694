import {
  faCheckCircle,
  faCircleNotch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { UpdateTags } from "../../api/tags/updateTags";
// import useStyles from './styles';
import { Form, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "@material-ui/core";
import { UpdateTagPic } from "../../api/tags/updateTagPic";
import { UpdateWbsedcl } from "../../api/Electricity/updateWbsedcl";
import { callAPI } from "../../api/callAPI";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux";
export const EditWbsedcl: React.FC<{
  closeView: () => void;
  data: any;
}> = ({ closeView = () => {}, data }) => {
  const initialState = "";
  const [premiseNo, setPremiseNo] = useState<string>(data.premise_no);
  // const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {token,refreshToken,userRole} = useSelector((state:RootState) => state.user)
  const dispatch = useAppDispatch()
  const [Block, setBlock] = useState(data.block);
  const [Plot, setPlot] = useState<number>(data.plot_no);
  const [ConsumerName, setConsumerName] = useState(data.consumer_name);
  const [ConsumerNo, setConsumerNo] = useState<number>(data.consumer_no);
  const [MeterNo, setMeterNo] = useState(data.meter_no);
  const [Type,setType] = useState(data.type_of_connection)
  const [Address,setAddress] = useState(data.address)
  
  const clearForm = () => {};

  const insertData = async () => {
    const data: object = {
      "consumer_name": ConsumerName,
      "consumer_no": ConsumerNo,
      "meter_no": MeterNo,
      "type_of_connection": Type,
      "block": Block,
      "plot_no": Plot,
      "premise_no": premiseNo,
      "address": Address
  }

    // console.log(data);

    const rows:any = await callAPI<any>(

      UpdateWbsedcl,
      { token: token, 
        //@ts-ignore
        data: data,  
    
      },
      refreshToken,
      dispatch
    );
    console.log(rows)

    if(rows?.data?.status === 'success'){
      enqueueSnackbar("Data Updated Sucessfully", { variant: "success" });
    }
    else{
      enqueueSnackbar("Failed To Update Data", { variant: "error" });
    }

  };
  const closeFormAfterInsertion = async (e: any) => {
    e.preventDefault();
    await insertData();
    closeView();
  };


  return (
  
    <>
      <div style={{ marginLeft: "30px", display: "flex" }}>
        {/* <div style={{display:'flex',flexDirection:'column',marginRight:'30px' }}>

      <img src={ImageURL} alt='no image availaible' style={{ width: 200}} />
          <Button variant="contained" component="label">
            Update Image
            <input onChange={(e)=>setImageURL(e.target.files)} type="file" hidden />
          </Button>

      </div> */}

        <Form>
          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
          <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Premise Number
              </Form.Label>
              <Form.Control
                style={{ width: 250,marginRight: "20px"  }}
                placeholder="Premise Number"
                value={premiseNo}
                onChange={(e) => setPremiseNo(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Plot No
              </Form.Label>
              <Form.Control
                style={{ width: 250, marginRight: "20px" }}
                placeholder="Plot No"
                value={Plot}
                //@ts-ignore
                onChange={(e) => setPlot(e.target.value)}
                type = 'number'
                min={0}
              />
            </Form.Group>

            
          </Form.Row>

          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
            
          <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Block
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Block"
                value={Block}
                onChange={(e) => setBlock(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Consumer Name
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Consumer Name"
                value={ConsumerName}
                onChange={(e) => setConsumerName(e.target.value)}
              />
            </Form.Group>

          </Form.Row>
          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
          <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                 Consumer No.
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Consumer No."
                value={ConsumerNo}
                //@ts-ignore
                onChange={(e) => setConsumerNo(e.target.value)}
                type = 'number'
                min={0}
              />
            </Form.Group>

            {/* <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Action Area
              </Form.Label>
              <Form.Control
                style={{ borderWidth: 1, borderColor: "lightgrey", width: 250 }}
                as="select"
                placeholder="Action Area"
                value={ActionArea}
                onChange={(e) => setActionArea(e.target.value)}
              >
                <option disabled value="">
                  Please Select
                </option>
                <option value="AA-1">AA-1</option>
                <option value="AA-2">AA-2</option>
                <option value="AA-3">AA-3</option>
              </Form.Control>
            </Form.Group> */}
              <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                 Meter No.
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Meter No."
                value={MeterNo}
                onChange={(e) => setMeterNo(e.target.value)}
        
              />
            </Form.Group>
      
          </Form.Row>

          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Type Of Connection
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Type Of Connection"
                value={Type}
                onChange={(e) => setType(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Address
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Address"
                value={Address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
 

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "5%",
        }}
      >
        <button className="btn btn-primary" onClick={closeFormAfterInsertion} disabled={userRole!=='admin'?true:false}>
          Update & Close
        </button>

        <button className="btn btn-danger" onClick={closeView}>
          Close
        </button>
      </div>
      </>
    
  );
};
