export const ThemeColor: {[key: string]: string} = {
    header: '#2962ff',
    background: '#323743',
    sidebar: '414755',
    container: '#272b34',
    defaultText: '#a4a7ae',
    focusedText: '#ffffff'
}

interface IColorRangeInfo {
  colorStart: number;
  colorEnd: number;
  useEndAsStart: boolean;
}

const defaultColorRangeInfo: IColorRangeInfo = {
    colorStart: 0,
    colorEnd: 0.65,
    useEndAsStart: true
}

export const calculatePoint = (
  i: number,
  intervalSize: number,
  colorRangeInfo: IColorRangeInfo
) => {
  var { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
  return useEndAsStart
    ? colorEnd - i * intervalSize
    : colorStart + i * intervalSize;
};

export const interpolateColors = (
  dataLength: number,
  colorScale: Function,
  colorRangeInfo: IColorRangeInfo = defaultColorRangeInfo
) => {
  var { colorStart, colorEnd } = colorRangeInfo;
  var colorRange = colorEnd - colorStart;
  var intervalSize = colorRange / dataLength;
  var i, colorPoint;
  var colorArray = [];

  for (i = 0; i < dataLength; i++) {
    colorPoint = calculatePoint(i, intervalSize, colorRangeInfo);
    colorArray.push(colorScale(colorPoint));
  }

  return colorArray;
};  