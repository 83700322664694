import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { dispatch } from "d3";
import { useSelector } from "react-redux";
import { AppDispatch, logout, RootState } from "../../redux";

export const Logout = async (
  
  dispatch: AppDispatch,
  refreshToken: string
  )=> {


    // const refreshToken = useSelector((state: RootState) => state.user.refreshToken);
 
    console.log(refreshToken)

 let config:AxiosRequestConfig = {
  method: 'post',
  url: 'https://api.sht.distronix.in:4443/api/users/logout',
  headers: { 
  
    'x-access-refresh-token': refreshToken
  },
 
};

axios(config)
.then((response) => {
  console.log(response.data);

})
.catch((error: any) => {
  const axiosTypedError: AxiosError = error;
  console.log(axiosTypedError.message);
  if(axiosTypedError.response?.status === 404) {
    console.log("Dispatching Logout function");
    dispatch(logout)
  }
});
  };