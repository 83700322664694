import axios, { AxiosRequestConfig, AxiosResponse } from "axios"

export interface type_getDataVersion {
  status: string;
  version: { table_name: string; version_no: string }[];
}

export const getDataVersion = async (
  {token}:Record<'token', string>
 
): Promise<AxiosResponse<any>> => {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "https://api.sht.distronix.in:4443/api/v1.1/version",
      headers: { 'x-access-token': token,},
    };
    const response: AxiosResponse<type_getDataVersion> = await axios(config);
    console.log("version: ", response.data)
    return response;
}