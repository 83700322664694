import axios, { AxiosRequestConfig, AxiosResponse } from "axios";


export const UpdateTags = async (
  {token,premise_no,Plot,Block,AplicationName,ActionArea,Latitude,Longitude,ProjectName}:Record<'token'|'premise_no'|'Plot'|'Block'|'AplicationName'|
  'ProjectName'|'ActionArea'|'Latitude'|'Longitude', string>
 
): Promise<AxiosResponse<any>>=> {

   let data = {
    "plot_no": Plot,
    "block": Block,
    "application_name": AplicationName,
    "project_name": ProjectName,
    "action_area": ActionArea,
    "latitude": Latitude,
    "longitude": Longitude
   }

  var config:any = {
    method: "post",
    url:
      "https://api.sht.distronix.in:4443/api/v1.2/tags/updatePremise",
    headers: {
        'Content-Type': 'application/json',
        'x-access-token':token
    },
    params: {
      premise_no: premise_no,
    },
    data:data
  };

  // const result2 = ApiHandler({ config: config, retry: 0 })
  // const result = await axios(config)
  //   .then((res) => ({ data: res.data, success: true, error: "" }))
  //   .catch((e) => ({ error: e, success: false, data: [] }));
  // console.log(result);
  // return result;
  const response: AxiosResponse<any> = await axios(config);
  console.log(response.data)
  return response;
};