import React from "react";
import { Link } from "react-router-dom";

export const Error404 = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div>
        <h1 style={{ fontSize: "12em", textAlign: "center", lineHeight:.5 }}>404</h1>
        <h1 style={{ fontSize: "3.5em", textAlign: "center" }}>Page Not Found</h1>
        <p style={{textAlign:'center', fontSize:"1.4em"}}>Go Back to <Link to="/" >Home</Link></p>
      </div>
    </div>
  );
};
