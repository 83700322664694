import { faBan, faBolt, faMoneyBillAlt, faSprayCan, faTag, faTags, faTrash, faWifi, faSpinner, faShuttleVan } from "@fortawesome/free-solid-svg-icons";
import Color from "color";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateRange, Card } from "../../components";
import Styles from "./index.module.css";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import Paper from "@material-ui/core/Paper";
import * as MockData from './mockdata';
import { useSelector } from "react-redux";
import { getAndStoreDateRangeKPIs, getAndStoreTags, getAndStoreTodaysKPIs, RootState, useAppDispatch } from '../../redux';
import { KPIs } from '../../api';
import { useActionAreaWiseBinsDataRange, useActionAreaWiseBinsDataToday } from '../../hooks';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { interpolateColors } from '../../Util';
import * as d3 from 'd3';
import moment from "moment";
import {getBinsData, getDatewiseBinsData} from '../../api/bins'
import { callAPI } from "../../api/callAPI";
import { getAvgKpi } from "../../api/tags/getAvgKpi";
export function Dashboard() {
  const PieChart1 = useRef(null)
  const PieChart2 = useRef(null);
  const PieChart3 = useRef(null);
  const PieChart4 = useRef(null);
  const PieChart5 = useRef(null);
  const PieChart6 = useRef(null);
  const PieChart7 = useRef(null);
  const PieChart8 = useRef(null);
  const baseWidth = document.body.clientWidth - 240;
  const Width = useRef(baseWidth);
  const [ActionAreaTodayBinsData, setActionAreaTodayBinsData] = useState<Object[]>([]);
  const [ActionAreaTodayBinsDataRange, setActionAreaTodayBinsDataRange] = useState<
    Object[]
  >([]);
  const [startDate,setStartDate] = useState(moment())
  const [endDate,setEndDate] = useState(moment())
  const [daterangeData,setDateRangeData] = useState([])

  const [avgKpi,setAvgKpi] = useState({
     
    total_tags: 0,
    total_cleaned:0 ,
    total_uncleaned: 0,
    total_scaned_tags: 0,
    scaned_by_swm: 0,
    scaned_by_mosquito: 0,
    scaned_by_electrical: 0,
    total_vts_bincheck:0
  })
  const [binsData,setBinsData] = useState([])
  const setDateRange = (start_date: moment.Moment, end_date: moment.Moment) => {
   
      setStartDate(start_date)
      setEndDate(end_date)
  }
  // console.log = function() {}
  const {kpi, SelectedDateRange} = useSelector((state: RootState) => state.kpi)
  const dispatch = useAppDispatch();
   const {token,refreshToken} = useSelector((state:RootState) => state.user)
  // const {
  //   data: ActionAreaWiseBinsDataToday,
  //   isError: ActionAreaWiseBinsDataTodayErr,
  //   isLoading: ActionAreaWiseBinsDataTodayLoading,
  // } = useActionAreaWiseBinsDataToday();
  // const ActionAreaWiseBinsData = useActionAreaWiseBinsDataToday();
  // const ActionAreaWiseBinsDataDatewise = useActionAreaWiseBinsDataRange(startDate,endDate);

  // console.log({'datewise bins data':ActionAreaWiseBinsDataDatewise.data});
  // useEffect(() => {
  //   const div = document.getElementById("MainContainer");
  //   if (div !== null && !!div.clientWidth) Width.current = div.clientWidth;
  // }, [Width.current]);
  
 
  const GetAndStoreKPIs = useCallback(async () => {
    await dispatch<any>(getAndStoreTodaysKPIs());
  }, [dispatch]);


  useEffect(() => {
   
    GetAndStoreKPIs();
    
  }, [ GetAndStoreKPIs]);

  useEffect(()=>{

    const fetchData = async()=>{

      const data = await callAPI<any>(
        getDatewiseBinsData,
        //@ts-ignore
        { token: token, startDate: startDate, endDate: startDate },
        refreshToken,
        dispatch
      );
    
      setDateRangeData(data?.data?.bins)
      //  console.log('bins Datewise --->',data?.data.bins)
    }

   fetchData()

  },[startDate,endDate])

  useEffect(()=>{

    const fetchData = async()=>{

      const data = await callAPI<any>(
        getBinsData,
    
        { token: token},
        refreshToken,
        dispatch
      );
    
      setBinsData(data?.data?.bins)
       console.log('bins Datewise2 --->',data?.data.bins)
    }

   fetchData()

  },[])

  useEffect(()=>{
     
    const fetchAvgKpi = async()=>{

      const data = await callAPI<any>(
        getAvgKpi,
        { token: token, start_date: moment(startDate).format('YYYY-MM-DD'), end_date: moment(endDate).format('YYYY-MM-DD') },
        refreshToken,
        dispatch
      );
    
      setAvgKpi(data?.data?.kpi)
      //  console.log('bins Datewise --->',data?.data.bins)
    }
    const fetchData = async()=>{

      const data = await callAPI<any>(
        getDatewiseBinsData,
        //@ts-ignore
        { token: token, startDate: startDate, endDate: startDate },
        refreshToken,
        dispatch
      );
    
      setDateRangeData(data?.data?.bins)
      //  console.log('bins Datewise --->',data?.data.bins)
    }

   fetchData()
   fetchAvgKpi()
     

  },[startDate,endDate])

  return (
    <div
      // id="MainContainer"
      style={{ flexDirection: "column", display: "flex", flex: 1 }}
    >
      {/* <div className={Styles.DateRangePicker}>
        <DateRange />
      </div> */}
      <h1>Today's Overview</h1>
      <div className={Styles.CardContainer} >
        <Card
          Icon={faTags}
          Value={kpi[KPIs.total_tags] | 0}
          Title="Total Tags"
          Description="The total number of deployed tags."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#e47a26", "hex")}
        />
        <Card
          Icon={faWifi}
          Mask={faTag}
          Value={kpi[KPIs.total_scaned_tags] | 0}
          Title="Total Scanned Tags"
          Description="The total number of deployed cards which are scanned today."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#e1be24", "hex")}
          IconOptions={{
            maskUnits: 0.5,
          }}
        />
        <Card
          Icon={faTrash}
          Value={kpi[KPIs.total_cleaned] | 0}
          Title="Total Bins Cleaned"
          Description="The total number of deployed cards scanned by the solid waste cleaning agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#36bea6", "hex")}
        />
        <Card
          Icon={faBan}
          Mask={faTrash}
          Value={kpi[KPIs.total_uncleaned] | 0}
          Title="Total Tags not Scanned"
          Description="The total number of deployed cards not scanned by the solid waste cleaning agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#e61f26", "hex")}
        />
       
        {/* <Card
          Icon={faSprayCan}
          Value={kpi[KPIs.scaned_by_mosquito] | 0}
          Title="Scanned by Mosquito Service"
          Description="The total number of deployed cards scanned by the mosquito repellent agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#A31EF5", "hex")}
        />
        <Card
          Icon={faBolt}
          Value={kpi[KPIs.scaned_by_electrical] | 0}
          Title="Scanned by Power Service"
          Description="The total number of deployed cards scanned by the power service agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#3366FF", "hex")}
        /> */}
      </div>
      <div className={Styles.CardContainer} >
        <Paper className={Styles.ChartContainers} style={{ maxWidth: 400 }}>
          {binsData?.length===0 && !!binsData ? (
            <FontAwesomeIcon
              size="lg"
              color="#bbbbbb"
              icon={faSpinner}
              spin={true}
            />
          ) : (
            <Doughnut
              ref={PieChart1}
              // data={MockData.PieData1}
              data={{
                labels: binsData.map(
                  (a: any) => a.ActionArea
                ),
                datasets: [
                  {
                    label: "Bins Cleaned",
                    data: binsData?.map(
                      (a: any) => a.CleanedBins
                    ),
                    backgroundColor: interpolateColors(
                      binsData.length,
                      d3.interpolateCool
                    ),
                    borderColor: "white",
                    borderWidth: 1,
                  },
                ],
              }}
              height={280}
              options={{
                legend: {
                  display: false,
                },
                circumference: 2 * Math.PI, //1.64 * Math.PI
                cutoutPercentage: 80,
                rotation: 0.5 * Math.PI, //0.68 * Math.PI
                animation: {
                  animateRotate: true,
                  animateScale: true,
                  duration: 1000,
                  easing: "easeInOutCubic",
                },
                title: {
                  text: "Bins Cleaned Action Area wise",
                  display: true,
                  position: "top",
                  fontColor: "#dddddd",
                  fontSize: 20,
                  fontFamily: "Poppins",
                },
              }}
            />
          )}
        </Paper>
        <Paper
          className={Styles.ChartContainers}
          style={{ maxWidth: 400 }}
          variant="outlined"
        >
          {binsData?.length===0 && !!binsData ? (
            <FontAwesomeIcon
              size="lg"
              color="#bbbbbb"
              icon={faSpinner}
              spin={true}
            />
          ) : (
            <Doughnut
              ref={PieChart2}
              data={{
                labels: binsData?.map(
                  (a: any) => a.ActionArea
                ),
                datasets: [
                  {
                    label: "Bins Uncleaned",
                    data: binsData?.map(
                      (a: any) => a.UncleanedBins
                    ),
                    backgroundColor: interpolateColors(
                     binsData.length,
                      d3.interpolateWarm
                    ),
                    borderColor: "white",
                    borderWidth: 1,
                  },
                ],
              }}
              height={280}
              options={{
                legend: {
                  display: false,
                },
                circumference: 2 * Math.PI, //1.64 * Math.PI
                cutoutPercentage: 80,
                rotation: 0.5 * Math.PI, //0.68 * Math.PI
                animation: {
                  animateRotate: true,
                  animateScale: true,
                  duration: 1000,
                  easing: "easeInOutCubic",
                },
                title: {
                  text: "Bins Uncleaned Action Area wise",
                  display: true,
                  position: "top",
                  fontColor: "#dddddd",
                  fontSize: 20,
                  fontFamily: "Poppins",
                },
              }}
            />
          )}
        </Paper>
        <Paper className={Styles.ChartContainers} style={{ maxWidth: 400 }}>
          {binsData.length===0 && !!binsData ? (
            <FontAwesomeIcon
              size="lg"
              color="#bbbbbb"
              icon={faSpinner}
              spin={true}
            />
          ) : (
            <Doughnut
              ref={PieChart3}
              data={{
                labels: binsData
                  .map((a: any) => a.Blocks)
                  .reduce((a: any, b: any) => [...a, ...b], [])
                  .map((a: any) => a.name),
                datasets: [
                  {
                    label: "Bins Uncleaned",
                    data: binsData
                      .map((a: any) => a.Blocks)
                      .reduce((a: any, b: any) => [...a, ...b], [])
                      .map((a: any) => a.CleanedBins),
                    backgroundColor: interpolateColors(
                      binsData
                        .map((a: any) => a.Blocks.length)
                        .reduce((a: any, b: any) => a + b, 0),
                      d3.interpolateCool
                    ),
                    borderColor: "white",
                    borderWidth:
                    1,
                  },
                ],
              }}
              height={280}
              options={{
                legend: {
                  display: false,
                },
                circumference: 2 * Math.PI, //1.64 * Math.PI
                cutoutPercentage: 80,
                rotation: 0.5 * Math.PI, //0.68 * Math.PI
                animation: {
                  animateRotate: true,
                  animateScale: true,
                  duration: 1000,
                  easing: "easeInOutCubic",
                },
                title: {
                  text: "Bins Cleaned Block wise",
                  display: true,
                  position: "top",
                  fontColor: "#dddddd",
                  fontSize: 20,
                  fontFamily: "Poppins",
                },
              }}
            />
          )}
        </Paper>
        <Paper
          className={Styles.ChartContainers}
          style={{ maxWidth: 400 }}
          variant="outlined"
        >
          {binsData.length===0 && !!binsData ? (
            <FontAwesomeIcon
              size="lg"
              color="#bbbbbb"
              icon={faSpinner}
              spin={true}
            />
          ) : (
            <Doughnut
              ref={PieChart4}
              data={{
                labels: binsData
                  .map((a: any) => a.Blocks)
                  .reduce((a: any, b: any) => [...a, ...b], [])
                  .map((a: any) => a.name),
                datasets: [
                  {
                    label: "Bins Uncleaned",
                    data: binsData
                      .map((a: any) => a.Blocks)
                      .reduce((a: any, b: any) => [...a, ...b], [])
                      .map((a: any) => a.UncleanedBins),
                    backgroundColor: interpolateColors(
                      binsData
                        .map((a: any) => a.Blocks.length)
                        .reduce((a: any, b: any) => a + b, 0),
                      d3.interpolateWarm
                    ),
                    borderColor: "white",
                    borderWidth: 1,
                  },
                ],
              }}
              height={280}
              options={{
                legend: {
                  display: false,
                },
                circumference: 2 * Math.PI, //1.64 * Math.PI
                cutoutPercentage: 80,
                rotation: 0.5 * Math.PI, //0.68 * Math.PI
                animation: {
                  animateRotate: true,
                  animateScale: true,
                  duration: 1000,
                  easing: "easeInOutCubic",
                },
                title: {
                  text: "Bins Uncleaned Block wise",
                  display: true,
                  position: "top",
                  fontColor: "#dddddd",
                  fontSize: 20,
                  fontFamily: "Poppins",
                },
              }}
            />
          )}
        </Paper>
      </div>
      {/* <div className={Styles.CardContainer} style={{ width: Width.current }}>
        <Paper className={Styles.ChartContainers} variant="outlined">
          <Line data={MockData.LineData1} options={MockData.LineOptions} />
        </Paper>
      </div> */}
      <br />
      <hr />
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: 20, paddingLeft: 10}}>
        <h1>Overview as per Date Range</h1>
        <DateRange  setDateRange={setDateRange}/>
      </div>
      <div className={Styles.CardContainer} >
        <Card
          Icon={faWifi}
          Mask={faTag}
          Value={
            avgKpi?.total_scaned_tags
           
          }
          Title="Total Scanned Tags"
          Description="The total number of deployed cards which are scanned today."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#e1be24", "hex")}
          IconOptions={{
            maskUnits: 0.5,
          }}
        />
        <Card
          Icon={faTrash}
          Value={
            avgKpi?.total_cleaned
          }
          Title="Total Bins Cleaned"
          Description="The total number of deployed cards scanned by the solid waste cleaning agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#36bea6", "hex")}
        />
        <Card
          Icon={faBan}
          Mask={faTrash}
          Value={

            avgKpi?.total_uncleaned
          }
          Title="Total Tags not Scanned"
          Description="The total number of deployed cards not scanned by the solid waste cleaning agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#e61f26", "hex")}
        />
          <Card
            Icon={faShuttleVan}
            Mask={faTag}
            Value={avgKpi?.total_vts_bincheck}
            Title="Vts Cleaned"
            Description="The total number of deployed cards which are scanned by VTS today."
            FontColor={Color.rgb(255, 255, 255)}
            BackgroundColor={Color("#4d41ba", "hex")}
           
          />
        {/* <Card
          Icon={faSprayCan}
          Value={
            !!SelectedDateRange && SelectedDateRange[KPIs.scaned_by_mosquito]
              ? SelectedDateRange[KPIs.scaned_by_mosquito]
              : 0
          }
          Title="Scanned by Mosquito Service"
          Description="The total number of deployed cards scanned by the mosquito repellent agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#A31EF5", "hex")}
        />
        <Card
          Icon={faBolt}
          Value={
            !!SelectedDateRange && SelectedDateRange[KPIs.scaned_by_electrical]
              ? SelectedDateRange[KPIs.total_scaned_tags]
              : 0
          }
          Title="Scanned by Power Service"
          Description="The total number of deployed cards scanned by the power service agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#3366FF", "hex")}
        /> */}
      </div>
      <div className={Styles.CardContainer}>
        <Paper className={Styles.ChartContainers} style={{ maxWidth: 400 }}>
          <Doughnut
            ref={PieChart5}
            data={{
              labels: daterangeData.map(
                (a: any) => a.ActionArea
              ),
              datasets: [
                {
                  label: "Bins Cleaned",
                  data: daterangeData.map(
                    (a: any) => a.CleanedBins
                  ),
                  backgroundColor: interpolateColors(
                    daterangeData?.length,
                    d3.interpolateCool
                  ),
                  borderColor: "white",
                  borderWidth: 1,
                },
              ],
            }}
            height={280}
            options={{
              legend: {
                display: false,
              },
              circumference: 2 * Math.PI, //1.64 * Math.PI
              cutoutPercentage: 80,
              rotation: 0.5 * Math.PI, //0.68 * Math.PI
              animation: {
                animateRotate: true,
                animateScale: true,
                duration: 1000,
                easing: "easeInOutCubic",
              },
              title: {
                text: "Bins Cleaned Action Area wise",
                display: true,
                position: "top",
                fontColor: "#dddddd",
                fontSize: 20,
                fontFamily: "Poppins",
              },
            }}
          />
        </Paper>
        <Paper
          className={Styles.ChartContainers}
          style={{ maxWidth: 400 }}
          variant="outlined"
        >
          <Doughnut
            ref={PieChart6}
            data={{
              labels: daterangeData.map(
                (a: any) => a.ActionArea
              ),
              datasets: [
                {
                  label: "Bins Uncleaned",
                  data: daterangeData.map(
                    (a: any) => a.UncleanedBins
                  ),
                  backgroundColor: interpolateColors(
                    daterangeData?.length,
                    d3.interpolateWarm
                  ),
                  borderColor: "white",
                  borderWidth: 1,
                },
              ],
            }}
            height={280}
            options={{
              legend: {
                display: false,
              },
              circumference: 2 * Math.PI, //1.64 * Math.PI
              cutoutPercentage: 80,
              rotation: 0.5 * Math.PI, //0.68 * Math.PI
              animation: {
                animateRotate: true,
                animateScale: true,
                duration: 1000,
                easing: "easeInOutCubic",
              },
              title: {
                text: "Bins Uncleaned Action Area wise",
                display: true,
                position: "top",
                fontColor: "#dddddd",
                fontSize: 20,
                fontFamily: "Poppins",
              },
            }}
          />
        </Paper>
        <Paper className={Styles.ChartContainers} style={{ maxWidth: 400 }}>
          <Doughnut
            ref={PieChart7}
            data={{
              labels: daterangeData
                .map((a: any) => a.Blocks)
                .reduce((a: any, b: any) => [...a, ...b], [])
                .map((a: any) => a.name),
              datasets: [
                {
                  label: "Bins Cleaned",
                  data: daterangeData
                    .map((a: any) => a.Blocks)
                    .reduce((a: any, b: any) => [...a, ...b], [])
                    .map((a: any) => a.CleanedBins),
                  backgroundColor: interpolateColors(
                    daterangeData
                      ?.map((a: any) => a.Blocks.length)
                      .reduce((a: any, b: any) => a + b, 0),
                    d3.interpolateCool
                  ),
                  borderColor: "white",
                  borderWidth:
                  1,
                },
              ],
            }}
            height={280}
            options={{
              legend: {
                display: false,
              },
              circumference: 2 * Math.PI, //1.64 * Math.PI
              cutoutPercentage: 80,
              rotation: 0.5 * Math.PI, //0.68 * Math.PI
              animation: {
                animateRotate: true,
                animateScale: true,
                duration: 1000,
                easing: "easeInOutCubic",
              },
              title: {
                text: "Bins Cleaned Block wise",
                display: true,
                position: "top",
                fontColor: "#dddddd",
                fontSize: 20,
                fontFamily: "Poppins",
              },
            }}
          />
        </Paper>
        <Paper
          className={Styles.ChartContainers}
          style={{ maxWidth: 400 }}
          variant="outlined"
        >
          <Doughnut
            ref={PieChart8}
            data={{
              labels: daterangeData
                .map((a: any) => a.Blocks)
                .reduce((a: any, b: any) => [...a, ...b], [])
                .map((a: any) => a.name),
              datasets: [
                {
                  label: "Bins Uncleaned",
                  data: daterangeData
                    .map((a: any) => a.Blocks)
                    .reduce((a: any, b: any) => [...a, ...b], [])
                    .map((a: any) => a.UncleanedBins),
                  backgroundColor: interpolateColors(
                    daterangeData
                      ?.map((a: any) => a.Blocks.length)
                      .reduce((a: any, b: any) => a + b, 0),
                    d3.interpolateWarm
                  ),
                  borderColor: "white",
                  borderWidth:
                  1,
                },
              ],
            }}
            height={280}
            options={{
              legend: {
                display: false,
              },
              circumference: 2 * Math.PI, //1.64 * Math.PI
              cutoutPercentage: 80,
              rotation: 0.5 * Math.PI, //0.68 * Math.PI
              animation: {
                animateRotate: true,
                animateScale: true,
                duration: 1000,
                easing: "easeInOutCubic",
              },
              title: {
                text: "Bins Uncleaned Block wise",
                display: true,
                position: "top",
                fontColor: "#dddddd",
                fontSize: 20,
                fontFamily: "Poppins",
              },
            }}
          />
        </Paper>
      </div>
      {/* <div className={Styles.CardContainer} style={{ width: Width.current }}>
        <Paper className={Styles.ChartContainers} variant="outlined">
          <Line data={MockData.LineData1} options={MockData.LineOptions} />
        </Paper>
      </div> */}
      {/* <div className={Styles.CardContainer} style={{ width: Width.current }}>
        <Paper className={Styles.ChartContainers} variant="outlined">
          <Bar data={MockData.BarData} options={MockData.BarOptions} />
        </Paper>
      </div> */}
    </div>
  );
}
