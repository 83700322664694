import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const CreateWbsedcl = async ({token,data,}:Record<'token'|'data', any>

): Promise<AxiosResponse<any>> => {
  
  const config: AxiosRequestConfig = {
    method: "post",
    url: "https://api.sht.distronix.in:4443/api/v1.2//tags/CreateMasterWbsedcl",
    headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
    },
   data:data
  };

  const response: AxiosResponse<any> = await axios(config);
  console.log(response.data)
  return response;
};