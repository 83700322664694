import React,{useCallback, useEffect, useRef, useState} from 'react'
import {Bar} from 'react-chartjs-2'
//@ts-ignore
import Base64Downloader from 'react-base64-downloader';
import { Button, Paper } from '@material-ui/core';
import Styles from "./index.module.css";
const BarTotalCount: React.FC<{
    rowData: any;
    chartRef: any;
    closeView: () => void;
    startDate:string;
    endDate:string

  }>=({rowData, chartRef,closeView,startDate,endDate}) => {

    const [base64, setBase64] = useState("");
    const [flag, setFlag] = useState(false);
  

    const handleClick = async () => {
      await setBase64(chartRef?.current.chartInstance.toBase64Image());
      setFlag(true);
    };
   
    return (
      <>
        <Paper className={Styles.ChartContainers} variant="outlined">
          <Bar
            data={{
              labels: rowData.map((item: any) => item.date),
              datasets: [
                {
                  label: "Total",
                  data: rowData.map((item: any) => item.TotalBins),
                  backgroundColor: "yellow",
                  borderColor: "blue",
                  borderWidth: 1,
                  stack: "stack 0",
                },
                {
                  label: "Cleaned",
                  data: rowData.map((item: any) => item.CleanedBins),
                  backgroundColor: "green",
                  borderColor: "blue",
                  borderWidth: 1,
                  stack: "stack 0",
                },
                {
                  label: "Uncleaned",
                  data: rowData.map((item: any) => item.UncleanedBins),
                  backgroundColor: "red",
                  borderColor: "blue",
                  borderWidth: 1,
                  stack: "stack 0",
                },
                {
                  label: "VTS Bin Check",
                  data: rowData.map((item: any) => item.vtsBinCheck),
                  backgroundColor: "blue",
                  borderColor: "white",
                  borderWidth: 1,
                  stack: "stack 0",
                },
              ],
            }}
            height={500}
            width={100}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                xAxes: [
                  {
                    type: "category",
                    stacked: true,
                    ticks: { mirror: true, stepSize: 1 },
                  },
                ],
                yAxes: [{ stacked: true, }],
              },
            }}
            ref={chartRef}
          />
        </Paper>
        <div style={{ marginTop: "15px" }}>
        {!flag && (
          <Button variant="contained" color="primary" onClick={handleClick}>
            Generate png
          </Button>
        )}

        {!!flag && (
          <Base64Downloader
            //@ts-ignore
            base64={base64}
            downloadName={`Daily-Total-Report-Graph(${startDate}to${endDate})`}
            //  className="btn btn-outline-info"
            style={{ fontWeight: "bold", color: "Blue" }}
          >
            Export
          </Base64Downloader>
        )}

        <Button
          variant="outlined"
          color="secondary"
          onClick={closeView}
          style={{ marginLeft: "10px" }}
        >
          Close
        </Button>
      </div>
      </>
    );
}

export default BarTotalCount
