import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {FlagState} from '../../index'

const initialState: FlagState = {
  flags: [],
};

interface FlagUpdated {
  name: string;
  currentValue: string;
}

interface FlagAdded extends FlagUpdated {
  values: string[];
}

export const FlagSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    flagAdd: (
      state: FlagState,
      action: PayloadAction<FlagAdded>
    ): FlagState => {
      const { name, values, currentValue } = action.payload;
      return {
        flags: [...state.flags, { name, values, currentValue }],
      };
    },
    flagRemove: (
      state: FlagState,
      action: PayloadAction<FlagUpdated>
    ): FlagState => {
      const { name } = action.payload;
      return {
        flags: [...state.flags.filter((flag) => flag.name !== name)],
      };
    },
    flagUpdate: (
      state: FlagState,
      action: PayloadAction<FlagUpdated>
    ): FlagState => {
      const { name, currentValue } = action.payload;
      return {
        flags: [
          ...state.flags.map((flag) =>
            flag.name === name ? { ...flag, currentValue } : flag
          ),
        ],
      };
    },
  },
});

export const { flagAdd, flagRemove, flagUpdate } = FlagSlice.actions;
