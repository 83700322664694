import React, { useState } from "react";
import styles from "./index.module.css";
import * as types from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSlidersH,
  faLayerGroup,
  faChartPie,
  faBolt,
  faDatabase,
  faMap,
  faUserCog,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";
import { ReportMenu } from "../ReportMenu";
export const SideBar = (props: types.SideBarProps) => {
  const [ActiveButton, setActiveButton] = useState<
    | "dashboard"
    | "mapview"
    | "analytics"
    | "services"
    | "reports"
    | "alerts"
    | "data"
    | "settings"
  >("dashboard");
  const [SideBarState, setSideBarState] = useState<"COLLAPSED" | "EXPANDED">(
    "EXPANDED"
  );
  const [ActiveReport, setActiveReport] = useState<
    | ""
    | "actionarea"
    | "block"
    | "cleaned"
    | "uncleaned"
    | "vts"
    | "total"
    | "regular"
    | "premise"
  >("");
  const [ActiveData, setActiveData] = useState<"" | "tags" | "Electricity">("");
  const [ReportSubMenu, setReportSubMenu] = useState(false);
  const [MasterSubMenu, setMasterSubMenu] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setReportSubMenu((s) => !s);
  };

  const handleMasterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setMasterSubMenu((s) => !s);
  };

  const handleClose = () => {
    setReportSubMenu(false);
    setMasterSubMenu(false);
    setActiveReport("");
  };

  return (
    <div
      className={styles.SideBar}
      style={{ width: SideBarState === "EXPANDED" ? 240 : 60 }}
    >
      <div className={styles.SideBarToggleButton}>
        {SideBarState === "EXPANDED" ? (
          <Tooltip arrow placement="right" title="Collapse the SideBar">
            <FontAwesomeIcon
              size="lg"
              color="#999999"
              style={{ float: "right", cursor: "pointer" }}
              icon={faAngleDoubleLeft}
              onClick={() => setSideBarState("COLLAPSED")}
            />
          </Tooltip>
        ) : (
          <Tooltip arrow placement="right" title="Expand the Sidebar">
            <FontAwesomeIcon
              size="lg"
              color="#999999"
              style={{ float: "right", cursor: "pointer" }}
              icon={faAngleDoubleRight}
              onClick={() => setSideBarState("EXPANDED")}
            />
          </Tooltip>
        )}
      </div>
      <div className={styles.UserInfo}>
        {/* <img
          style={
            SideBarState === "EXPANDED"
              ? { height: 64, width: 64, borderRadius: 32 }
              : { height: 40, width: 40, borderRadius: 20 }
          }
          className={styles.UserAvatar}
          src={props.UserAvatar}
          alt={props.UserName + " logo"}
        ></img> */}
        {SideBarState === "EXPANDED" && (
          <div className={styles.UserNameAndDesignation}>
            <div className={styles.UserName}>{props.UserName}</div>
            <div className={styles.UserDesignation}>
              {props.UserDesignation}
            </div>
          </div>
        )}
      </div>
      <div
        className={styles.AccountSettingsWrapper}
        onClick={() => props.openAccount((s) => !s)}
      >
        <Tooltip arrow placement="right" title="Account Settings">
          {SideBarState === "EXPANDED" ? (
            <>
              <div className={styles.AccountSettingsButton}>
                <FontAwesomeIcon
                  size="1x"
                  className={styles.SideBarOptionIcon}
                  color="#CCCCCC"
                  icon={faUserCog}
                />{" "}
                Account Settings
              </div>
            </>
          ) : (
            <div className={styles.SideBarOption}>
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faUserCog}
              />
            </div>
          )}
        </Tooltip>
      </div>
      <Tooltip arrow placement="right" title="Dashboard">
        <Link
          to="/user/dashboard"
          onClick={() => {
            handleClose();
            setActiveButton("dashboard");
          }}
        >
          {SideBarState === "EXPANDED" ? (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "dashboard" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faSlidersH}
              />
              <div className={styles.SideBarOptionTitle}>Dashboard</div>
            </div>
          ) : (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "dashboard" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faSlidersH}
              />
            </div>
          )}
        </Link>
      </Tooltip>

      <Tooltip arrow placement="right" title="Map View">
        <Link
          to="/user/map"
          onClick={() => {
            handleClose();
            setActiveButton("mapview");
          }}
        >
          {SideBarState === "EXPANDED" ? (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "mapview" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faMap}
              />
              <div className={styles.SideBarOptionTitle}>Map View</div>
            </div>
          ) : (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "mapview" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faMap}
              />
            </div>
          )}
        </Link>
      </Tooltip>

      <Tooltip arrow placement="right" title="Master Data">
        {SideBarState === "EXPANDED" ? (
          <div
            className={`${styles.SideBarOption} ${
              ActiveButton === "reports" ? styles.SideBarOptionActive : ""
            }`}
            aria-controls="report-menu"
            aria-haspopup="true"
            onClick={handleMasterClick}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.SideBarOptionIcon}
              color="#CCCCCC"
              icon={faChartPie}
            />
            <div className={styles.SideBarOptionTitle}>Master Data</div>
          </div>
        ) : (
          <div
            className={`${styles.SideBarOption} ${
              ActiveButton === "reports" ? styles.SideBarOptionActive : ""
            }`}
            aria-controls="report-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.SideBarOptionIcon}
              color="#CCCCCC"
              icon={faChartPie}
            />
          </div>
        )}
      </Tooltip>

      {!!MasterSubMenu && (
        <div style={{ visibility: MasterSubMenu ? "visible" : "collapse" }}>
          <Tooltip arrow placement="right" title="Tags">
            <Link
              to="/user/reports/master-data"
              onClick={() => {
                setActiveButton("data");
                setActiveData("tags");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarOption} ${
                    ActiveButton === "data" ? styles.SideBarOptionActive : ""
                  }`}
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faDatabase}
                  />
                  <div className={styles.SideBarOptionTitle}>Tags</div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarOption} ${
                    ActiveButton === "data" ? styles.SideBarOptionActive : ""
                  }`}
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faDatabase}
                  />
                </div>
              )}
            </Link>
          </Tooltip>

          <Tooltip arrow placement="right" title="Electricity">
            <Link
              to="/user/reports/electricity"
              onClick={() => {
                setActiveButton("data");
                setActiveData("tags");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarOption} ${
                    ActiveButton === "data" ? styles.SideBarOptionActive : ""
                  }`}
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faDatabase}
                  />
                  <div className={styles.SideBarOptionTitle}>Electricity</div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarOption} ${
                    ActiveButton === "data" ? styles.SideBarOptionActive : ""
                  }`}
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faDatabase}
                  />
                </div>
              )}
            </Link>
          </Tooltip>
        </div>
      )}

      <Tooltip arrow placement="right" title="Reports">
        {SideBarState === "EXPANDED" ? (
          <div
            className={`${styles.SideBarOption} ${
              ActiveButton === "reports" ? styles.SideBarOptionActive : ""
            }`}
            aria-controls="report-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.SideBarOptionIcon}
              color="#CCCCCC"
              icon={faChartPie}
            />
            <div className={styles.SideBarOptionTitle}>Reports</div>
          </div>
        ) : (
          <div
            className={`${styles.SideBarOption} ${
              ActiveButton === "reports" ? styles.SideBarOptionActive : ""
            }`}
            aria-controls="report-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.SideBarOptionIcon}
              color="#CCCCCC"
              icon={faChartPie}
            />
          </div>
        )}
      </Tooltip>
      {
        <div style={{ visibility: ReportSubMenu ? "visible" : "collapse" }}>
          {/* <Tooltip arrow placement="right" title="Master Data Report">
            <Link
              to="/user/reports/master-data"
              onClick={() => {
                setActiveButton("reports");
                setActiveReport("master");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "master" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                  <div className={styles.SideBarOptionTitle}>Master Data</div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "master" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                </div>
              )}
            </Link>
          </Tooltip> */}
          <Tooltip arrow placement="right" title="ActionArea-wise Data Report">
            <Link
              to="/user/reports/actionarea-wise-bins"
              onClick={() => {
                setActiveButton("reports");
                setActiveReport("actionarea");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "actionarea"
                      ? styles.SideBarOptionActive
                      : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                  <div className={styles.SideBarOptionTitle}>
                    ActionArea-wise
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "actionarea"
                      ? styles.SideBarOptionActive
                      : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                </div>
              )}
            </Link>
          </Tooltip>
          <Tooltip arrow placement="right" title="Premise Wise Report">
            <Link
              to="/user/reports/premise-wise"
              onClick={() => {
                setActiveButton("reports");
                setActiveReport("premise");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "premise"
                      ? styles.SideBarOptionActive
                      : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                  <div className={styles.SideBarOptionTitle}>
                    Premise-wise
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "actionarea"
                      ? styles.SideBarOptionActive
                      : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                </div>
              )}
            </Link>
          </Tooltip>
          <Tooltip arrow placement="right" title="Block-wise Data Report">
            <Link
              to="/user/reports/block-wise-bins"
              onClick={() => {
                setActiveButton("reports");
                setActiveReport("block");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "block" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                  <div className={styles.SideBarOptionTitle}>Block-wise</div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "block" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                </div>
              )}
            </Link>
          </Tooltip>
          <Tooltip arrow placement="right" title="Cleaned Bins Data Report">
            <Link
              to="/user/reports/bins-cleaned"
              onClick={() => {
                setActiveButton("reports");
                setActiveReport("cleaned");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "cleaned" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                  <div className={styles.SideBarOptionTitle}>Cleaned Bins</div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "cleaned" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                </div>
              )}
            </Link>
          </Tooltip>
          <Tooltip arrow placement="right" title="Uncleaned Bins Data Report">
            <Link
              to="/user/reports/bins-uncleaned"
              onClick={() => {
                setActiveButton("reports");
                setActiveReport("uncleaned");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "uncleaned"
                      ? styles.SideBarOptionActive
                      : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                  <div className={styles.SideBarOptionTitle}>
                    Uncleaned Bins
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "uncleaned"
                      ? styles.SideBarOptionActive
                      : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                </div>
              )}
            </Link>
          </Tooltip>

          <Tooltip arrow placement="right" title="VTS BIN CHECK">
            <Link
              to="/user/reports/vts-bin-check"
              onClick={() => {
                setActiveButton("reports");
                setActiveReport("vts");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "vts" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                  <div className={styles.SideBarOptionTitle}>VTS BIN CHECK</div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "vts" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                </div>
              )}
            </Link>
          </Tooltip>
          <Tooltip arrow placement="right" title="Daily Total Reports">
            <Link
              to="/user/reports/total-reports"
              onClick={() => {
                setActiveButton("reports");
                setActiveReport("total");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "total" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faCalculator}
                  />
                  <div className={styles.SideBarOptionTitle}>
                    Daily Total Report
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "total" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                </div>
              )}
            </Link>
          </Tooltip>
          <Tooltip arrow placement="right" title="Regular Scanned Reports">
            <Link
              to="/user/reports/regular-reports"
              onClick={() => {
                setActiveButton("reports");
                setActiveReport("regular");
              }}
            >
              {SideBarState === "EXPANDED" ? (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "regular" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faCalculator}
                  />
                  <div className={styles.SideBarOptionTitle}>
                    Regular Scanned Report
                  </div>
                </div>
              ) : (
                <div
                  className={`${styles.SideBarSubOption} ${
                    ActiveReport === "total" ? styles.SideBarOptionActive : ""
                  }`}
                  aria-controls="report-menu"
                  aria-haspopup="true"
                >
                  <FontAwesomeIcon
                    size="1x"
                    className={styles.SideBarOptionIcon}
                    color="#CCCCCC"
                    icon={faChartPie}
                  />
                </div>
              )}
            </Link>
          </Tooltip>
        </div>
      }
      {/* <Menu
        id="report-menu"
        anchorEl={ReportEl}
        keepMounted
        open={Boolean(ReportEl)}
        onClose={handleClose}
        style={{ top: 60 }}
      >
        <Link to="/user/reports/master-data">
          <MenuItem
            onClick={() => {
              setActiveButton("reports");
              handleClose();
            }}
          >
            Master Data
          </MenuItem>
        </Link>
        <MenuItem onClick={handleClose}>Bins Cleaned</MenuItem>
        <MenuItem onClick={handleClose}>Bins Not Cleaned</MenuItem>
        <MenuItem onClick={handleClose}>Bins Cleaned - Blockwise</MenuItem>
        <MenuItem onClick={handleClose}>Bins Cleaned - ActionAreawise</MenuItem>
      </Menu> */}
      {/* 
      <Tooltip arrow placement="right" title="Alerts">
        {SideBarState === "EXPANDED" ? (
          <div
            className={`${styles.SideBarOption} ${
              ActiveButton === "alerts" ? styles.SideBarOptionActive : ""
            }`}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.SideBarOptionIcon}
              color="#CCCCCC"
              icon={faBolt}
            />
            <div className={styles.SideBarOptionTitle}>Alerts</div>
          </div>
        ) : (
          <div
            className={`${styles.SideBarOption} ${
              ActiveButton === "alerts" ? styles.SideBarOptionActive : ""
            }`}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.SideBarOptionIcon}
              color="#CCCCCC"
              icon={faBolt}
            />
          </div>
        )}
      </Tooltip> */}

      {/* {SideBarState === "EXPANDED" ? (
        <div
          className={`${styles.SideBarOption} ${
            ActiveButton === "settings" ? styles.SideBarOptionActive : ""
          }`}
        >
          <FontAwesomeIcon
            size="1x"
            className={styles.SideBarOptionIcon}
            color="#CCCCCC"
            icon={faCog}
          />
          <div className={styles.SideBarOptionTitle}>Settings</div>
        </div>
      ) : (
        <div
          className={`${styles.SideBarOption} ${
            ActiveButton === "settings" ? styles.SideBarOptionActive : ""
          }`}
        >
          <FontAwesomeIcon
            size="1x"
            className={styles.SideBarOptionIcon}
            color="#CCCCCC"
            icon={faCog}
          />
        </div>
      )} */}
    </div>
  );
};
