import React, { useCallback, useEffect } from "react";
import "./App.css";
import { getAndStoreTags, RootState, useAppDispatch } from "./redux/index";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { GuestLayout, UserLayout } from "./layout";
import { SnackbarProvider } from "notistack";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {Error404} from "../src/pages/Error/Error404";
// console.log = function() {}

function App() {
  const flag = useSelector((state: RootState) => state.user.loggedIn);
  const dispatch = useAppDispatch();
  const GetAndStoreTags = useCallback(async () => {
    dispatch<any>(getAndStoreTags());
  }, [dispatch]);

  useEffect(() => {
    GetAndStoreTags();
  }, []);


  return (
    <Router>
      <SnackbarProvider maxSnack={3}>
        <Switch>
          <Route exact path="/">
            {flag ? (
              <Redirect to="/user/dashboard" />
            ) : (
              <Redirect to="/guest/signin" />
            )}
          </Route>
          <Route path="/guest" render={() => <GuestLayout />} />
          <Route path="/user" render={() => <UserLayout />} />
          <Route path="/404" component={Error404} />

          <Redirect to="/404" />
        </Switch>
      </SnackbarProvider>
    </Router>
  );
}

export default App;
