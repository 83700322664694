import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment";
import { templateAPI } from "../templateAPI";

export interface ITagUsageData2 {
  id: number;
  premise_no: string;
  isCleanedToday: boolean;
  lastCleanedAt: string;
  AgencyName: string;
}

/**
 * @async
 * @function getBinsUsageData2
 * @example await getBinsUsageData()
 * @example getBinsUsageData.then(r => {
 *  //do something
 * }).catch(e => {
 *  //handle error
 * })
 * @return {PromiseLike<ITagUsageData2[]>}
 *  returns a promise with tag data.
 */
export const getBinsUsageData2 = async ( {token,startDate,endDate}:Record<'token'|'startDate'|'endDate', string>): Promise<AxiosResponse<any>> => {
  var config: AxiosRequestConfig = {
    method: "get",
    url: "https://api.sht.distronix.in:4443/api/v1.1/tags/getAllTags2",
    headers: {'x-access-token': token ,},
    params: {
      start_date: startDate,
      service_id: 1,
      end_date: endDate,
    },
  };


const response: AxiosResponse<any> = await axios(config);
console.log("GetAllBin: ", response.data)
return response;
};
