import React, { useCallback, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
//@ts-ignore
import Base64Downloader from "react-base64-downloader";
import { Button, Paper } from "@material-ui/core";
import Styles from "./index.module.css";
const BarActionArea: React.FC<{
  rowData: any;
  chartRef: any;
  label: any;
  closeView: () => void;
  startDate:string;
  endDate:string
}> = ({ rowData, chartRef, label, closeView,startDate,endDate }) => {
  const [base64, setBase64] = useState("");
  const [flag, setFlag] = useState(false);

  const handleClick = async () => {
    await setBase64(chartRef?.current.chartInstance.toBase64Image());
    setFlag(true);
  };

  return (
    <>
      <Paper className={Styles.ChartContainers} variant="outlined">
        <Bar
          data={{
            labels: label,
            datasets: [
              {
                label: "AA1(total)",
                data: rowData.map((item: any) => {
                  if (item.ActionArea === "AA-1") return item.TotalBins;
                }),
                backgroundColor: "red",
                borderColor: "blue",
                borderWidth: 1,
                stack: "stack 0",
              },
              {
                label: "AA1(cleaned)",
                data: rowData.map((item: any) => {
                  if (item.ActionArea === "AA-1") return item.CleanedBins;
                }),
                backgroundColor: "orange",
                borderColor: "blue",
                borderWidth: 1,
                stack: "stack 0",
              },
              {
                label: "AA1(uncleaned)",
                data: rowData.map((item: any) => {
                  if (item.ActionArea === "AA-1") return item.UncleanedBins;
                }),
                backgroundColor: "pink",
                borderColor: "blue",
                borderWidth: 1,
                stack: "stack 0",
              },
              {
                label: "AA2(Total)",
                data: rowData
                  .filter((i: any) => i.ActionArea === "AA-2")
                  .map((item: any) => item.TotalBins),
                backgroundColor: "yellow",
                borderColor: "blue",
                borderWidth: 1,
                stack: "stack 1",
              },
              {
                label: "AA2(cleaned)",
                data: rowData
                  .filter((i: any) => i.ActionArea === "AA-2")
                  .map((item: any) => item.CleanedBins),
                backgroundColor: "green",
                borderColor: "blue",
                borderWidth: 1,
                stack: "stack 1",
              },
              {
                label: "AA2(uncleaned)",
                data: rowData
                  .filter((i: any) => i.ActionArea === "AA-2")
                  .map((item: any) => item.UncleanedBins),
                backgroundColor: "gray",
                borderColor: "blue",
                borderWidth: 1,
                stack: "stack 1",
              },
              {
                label: "AA3(Total)",
                data: rowData
                  .filter((i: any) => i.ActionArea === "AA-3")
                  .map((item: any) => item.TotalBins),
                backgroundColor: "aqua",
                borderColor: "blue",
                borderWidth: 1,
                stack: "stack 2",
              },
              {
                label: "AA3(cleaned)",
                data: rowData
                  .filter((i: any) => i.ActionArea === "AA-3")
                  .map((item: any) => item.CleanedBins),
                backgroundColor: "rgba(255,99,132,0.2)",
                borderColor: "blue",
                borderWidth: 1,
                stack: "stack 2",
              },
              {
                label: "AA3(uncleaned)",
                data: rowData
                  .filter((i: any) => i.ActionArea === "AA-3")
                  .map((item: any) => item.UncleanedBins),
                backgroundColor: "blue",
                borderColor: "blue",
                borderWidth: 1,
                stack: "stack 2",
              },
            ],
          }}
          height={500}
          width={100}
          options={{
            maintainAspectRatio: false,

            scales: {
              xAxes: [
                {
                  type: "category",
                  stacked: true,
                  ticks: { mirror: true, stepSize: 1 },
                },
              ],
              yAxes: [{ stacked: true }],
            },
          }}
          ref={chartRef}
        />
      </Paper>
      <div style={{ marginTop: "15px" }}>
        {!flag && (
          <Button variant="contained" color="primary" onClick={handleClick}>
            Generate png
          </Button>
        )}

        {!!flag && (
          <Base64Downloader
            //@ts-ignore
            base64={base64}
            downloadName={`Action-Area-Wise-Graph(${startDate}to${endDate})`}
            //  className="btn btn-outline-info"
            style={{ fontWeight: "bold", color: "Blue" }}
          >
            Export
          </Base64Downloader>
        )}

        <Button
          variant="outlined"
          color="secondary"
          onClick={closeView}
          style={{ marginLeft: "10px" }}
        >
          Close
        </Button>
      </div>
    </>
  );
};

export default BarActionArea;
