import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const UpdateTagPic = async (
  {token,imageFile,premise_no}:Record<'token'|'imageFile'|'premise_no', any>
 
): Promise<AxiosResponse<any>> => {

    let data = new FormData();
    data.append('file',imageFile);

  var config:AxiosRequestConfig = {
    method: "post",
    url:
      "https://api.sht.distronix.in:4443/api/v1.2/tags/updatePremisePic",
    headers: {
      'x-access-token': token,
    },
    params:{
      premise_no: premise_no
    },
     data:data
  };

  const response: AxiosResponse<any> = await axios(config);
  console.log("Add tag pic: ", response.data)
  return response;
};