import axios, { AxiosRequestConfig, AxiosResponse } from "axios";




export const GetWbsedclData = async (
  {token}:Record<'token', string>
 
  ): Promise<AxiosResponse<any>>=> {
  var config: any = {
    method: "get",
    url: "https://api.sht.distronix.in:4443/api/v1.2/tags/getAllwbsedcl",
    headers: {
      'x-access-token':token
    },
    // params: {
    //   start_date: startDate,
    //   end_date: endDate,
    // },
  };

  // const result2 = ApiHandler({ config: config, retry: 0 })
  const response: AxiosResponse<any> = await axios(config);
  console.log("Electricity: ", response.data)
  return response;
};