import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Paper } from "@material-ui/core";
import useStyles from "./styles";
import { green } from "@material-ui/core/colors";
import { callAPI } from "../../api/callAPI";
import { UpdateUserApi } from "../../api/Account/updateUser";
import { useSelector } from "react-redux";
import { login, RootState, useAppDispatch } from "../../redux";
import { UpdateUserPic } from "../../api/Account/updatePic";
import { Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSnackbar } from "notistack";
import crypto from "crypto";

const UpdateUser: React.FC<{ closeView: () => void }> = ({
  closeView = () => {},
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { token, refreshToken, userName, name } = useSelector(
    (state: RootState) => state.user
  );
  const classes = useStyles();

  const [postData, setPostData] = useState({
    userName: userName,

    password: "",

    name: name,
  });

  const [imageFile, setImageFile] = useState(null);

  const clear = () => {
    setPostData({
      userName: "",

      password: "",

      name: "",
    });
  };

  const handleChange = (e: any) => {
    console.log(e.target.files[0].name);
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const algorithm = "aes-256-cbc";

    // the cipher function
    const cipher = crypto.createCipheriv(
      algorithm,
      "12345678123456781234567812345678",
      "1234567812345678"
    );

    // encrypt the message
    // input encoding
    // output encoding
    // @ts-ignore
    let encryptedData = cipher.update(postData.password, "utf-8", "hex");
    // @ts-ignore
    encryptedData += cipher.final("hex");
    const data = await callAPI<any>(
      UpdateUserApi,
      {
        token: token,
        userName: postData.userName,

        password: encryptedData,

        name: postData.name,
      },
      refreshToken,
      dispatch
    );
    const data2 = await callAPI<any>(
      UpdateUserPic,
      {
        token: token,
        //@ts-ignore
        imageFile: imageFile,
      },
      refreshToken,
      dispatch
    );
    if (data2?.data?.status === "success") {
      enqueueSnackbar("Your Profile Updated Sucessfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Failed To Update Your Profile", { variant: "error" });
    }

    closeView();
    const newData = {
      ...data2?.data?.data,
      profilePic: `https://api.sht.distronix.in:4443${data2?.data?.data.photo_path.slice(
        11
      )}`,
    };
    //  console.log(newData)
    dispatch(login(newData));
  };

  return (
    <>
      <div style={{ marginLeft: "30px", display: "flex" }}>
        <Form>
          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                User Name
              </Form.Label>
              <Form.Control
                style={{ width: 250, marginRight: "20px" }}
                placeholder="User Name"
                value={postData.userName}
                onChange={(e) =>
                  setPostData({ ...postData, userName: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Name
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Name"
                value={postData.name}
                onChange={(e) =>
                  setPostData({ ...postData, name: e.target.value })
                }
              />
            </Form.Group>
          </Form.Row>

          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Password
              </Form.Label>
              <Form.Control
                type="password"
                style={{ width: 250 }}
                placeholder="Password"
                value={postData.password}
                onChange={(e) =>
                  setPostData({ ...postData, password: e.target.value })
                }
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
      {/* <label htmlFor="image" style={{ marginLeft: "10%" }}>
        Update Profile Image
      </label> */}
      {/* <input
        accept="image/*"
        id="image"
        name="image"
        type="file"
        onChange={handleChange}
        style={{ marginTop: "20px", marginLeft: "3%" }}
      /> */}
      <div style={{ marginTop: "5%", marginLeft: "15%" }}>
        <Button
          className={classes.buttonSubmit}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          onClick={handleSubmit}
        >
          Update & Close
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={clear}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: "10px" }}
          size="small"
          onClick={() => closeView()}
        >
          Close
        </Button>
      </div>
    </>
  );
};

export default UpdateUser;
