import {
  faCheckCircle,
  faCircleNotch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { UpdateTags } from "../../api/tags/updateTags";
// import useStyles from './styles';
import { Form, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "@material-ui/core";
import { CreateTags } from "../../api/tags/createTag";
import { callAPI } from "../../api/callAPI";
import { RootState, useAppDispatch } from "../../redux";
import { useSelector } from "react-redux";

export const AddTag: React.FC<{
  closeView: () => void;
}> = ({ closeView = () => {} }) => {
  const initialState = "";
  const [premiseNo, setPremiseNo] = useState<string>("");
  // const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {token,refreshToken} = useSelector((state:RootState) => state.user)
  const dispatch = useAppDispatch()
  const [Block, setBlock] = useState("");
  const [Plot, setPlot] = useState("");
  const [AplicationName, setAplicationName] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [ActionArea, setActionArea] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const clearForm = () => {};

  const insertData = async () => {
    // const data: any = {
    //   plot_no: Plot,
    //   block: Block,
    //   application_name: AplicationName,
    //   project_name: ProjectName,
    //   action_area: ActionArea,
    //   latitude: Latitude,
    //   longitude: Longitude,
    // };
    
    let data = new FormData();
    data.append("premise_no", premiseNo);
    data.append("plot_no", Plot);
    data.append("block", Block);
    data.append("application_name", AplicationName);
    data.append("project_name", ProjectName);
    data.append("action_area", ActionArea);
    data.append("lat", Latitude);
    data.append("long", Longitude);
    data.append("timestamp",moment().format("YYYY-MM-DD HH:mm:ss"));
       //@ts-ignore
    data.append("file", imageFile);
    // console.log(data);

      const rows:any = await callAPI<any>(
        CreateTags,
        { token: token, 
          //@ts-ignore
          data: data,  
      
        },
        refreshToken,
        dispatch
      );
  
}
  const closeFormAfterInsertion = async (e: any) => {
    e.preventDefault();
    await insertData();
    closeView();
  };
  const handleChange = (e: any) => {
    setImageFile(e.target.files[0]);
  };

  return (
  
    <>
      <div style={{ marginLeft: "30px", display: "flex" }}>
        {/* <div style={{display:'flex',flexDirection:'column',marginRight:'30px' }}>

      <img src={ImageURL} alt='no image availaible' style={{ width: 200}} />
          <Button variant="contained" component="label">
            Update Image
            <input onChange={(e)=>setImageURL(e.target.files)} type="file" hidden />
          </Button>

      </div> */}

        <Form>
          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
          <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Premise Number
              </Form.Label>
              <Form.Control
                style={{ width: 250,marginRight: "20px"  }}
                placeholder="Premise Number"
                value={premiseNo}
                onChange={(e) => setPremiseNo(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Plot No
              </Form.Label>
              <Form.Control
                style={{ width: 250, marginRight: "20px" }}
                placeholder="Plot No"
                value={Plot}
                onChange={(e) => setPlot(e.target.value)}
              />
            </Form.Group>

            
          </Form.Row>

          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
            
          <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Block
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Block"
                value={Block}
                onChange={(e) => setBlock(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Project Name
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Project Name"
                value={ProjectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </Form.Group>

          </Form.Row>
          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
          <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Application Name
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Application Name"
                value={AplicationName}
                onChange={(e) => setAplicationName(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Action Area
              </Form.Label>
              <Form.Control
                style={{ borderWidth: 1, borderColor: "lightgrey", width: 250 }}
                as="select"
                placeholder="Action Area"
                value={ActionArea}
                onChange={(e) => setActionArea(e.target.value)}
              >
                <option disabled value="">
                  Please Select
                </option>
                <option value="AA-1">AA-1</option>
                <option value="AA-2">AA-2</option>
                <option value="AA-3">AA-3</option>
              </Form.Control>
            </Form.Group>

      
          </Form.Row>

          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Latitude
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Latitude"
                value={Latitude}
                onChange={(e) => setLatitude(e.target.value)}
                type='number'
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Longitude
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Longitude"
                value={Longitude}
                onChange={(e) => setLongitude(e.target.value)}
                type='number'
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
      <label htmlFor="image" style={{marginLeft:'10%'}}>Add Tag Image</label>
      <input
        accept="image/*"
        id="image"
        name="image"
        type="file"
        onChange={handleChange}
        style={{ marginTop: "20px", marginLeft: "3%" }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "2%",
        }}
      >
        <button className="btn btn-primary" onClick={closeFormAfterInsertion}>
          Add & Close
        </button>

        <button className="btn btn-danger" onClick={closeView}>
          Close
        </button>
      </div>
    </>
  );
};
