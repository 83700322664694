import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { AppDispatch, updateAccessToken } from "../redux";
import qs from 'qs'

const returnAsAxiosError = <T>(e: any): AxiosError<T> => {
  const Err: AxiosError<T> = e;
  return Err;
}


export const getNewToken = async (
  refreshToken: string
): Promise<AxiosResponse<{ token: string }>> => {

  const data = {refreshToken:refreshToken}
  const config: AxiosRequestConfig = {
    method: "post",
    url: "https://api.sht.distronix.in:4443/api/users/token",
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      "Content-Type": "application/json",
    },
    data: data
  };
  const response: AxiosResponse<{ token: string }> = await axios(config);
  console.log("getNewToken: ", response.data)
  return response;
};

/** 
 * callAPI function
*/
export const callAPI: <T>(apiFunc: (arg: Record<any | "token", string>) => Promise<AxiosResponse<T>>, args: Record<any | "token", string>, refreshToken: string, dispatch: AppDispatch) => Promise<AxiosResponse<T> | null> = async <T>(
  apiFunc: (arg: Record<any | "token", string>) => Promise<AxiosResponse<T>>,
  args: Record<any | "token", string>,
  refreshToken: string,
  dispatch: AppDispatch
): Promise<AxiosResponse<T> | null> => {
  try {
    const result: AxiosResponse<T> = await apiFunc(args);
    if (result.status === 401) throw new Error("AccessTokenExpired");
    return result;
  } catch (error) {
    const ThisError = returnAsAxiosError<any>(error);
    if (
      (error instanceof Error && error.message === "AccessTokenExpired") ||
      ThisError.response?.status === 401
    ) {
      const newTokenResponse = await getNewToken(refreshToken);
      const newToken = newTokenResponse.data.token;
      dispatch(updateAccessToken(newToken));
      const result: AxiosResponse<T> = await apiFunc({
        ...args,
        token: newToken,
      });
      console.log("New Response Data => ", result.data);
      return result;
    }
    console.log("Error => ",error);
    return null;
  }
};
