import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment";
import { EndPoints } from "../endpoints";

export const getKPIs2 = async (  {token,start_date}:Record<'token'|'start_date', string>
 
): Promise<AxiosResponse<any>> => {
  const data = {
    start_date: start_date,
    end_date: start_date,
  };
  const config: AxiosRequestConfig = {
    method: "get",
    url: "https://api.sht.distronix.in:4443/api/v1.1/statistics/kpi",
    headers: {
     "x-access-token": token
    },
    params: data,
  };
  
  
  const response: AxiosResponse<any> = await axios(config);
  console.log("GetAllTagsDatewise: ", response.data)
  return response;
};

export const KPIs = {
  total_tags: "total_tags",
  total_cleaned: "total_cleaned",
  total_uncleaned: "total_uncleaned",
  total_scaned_tags: "total_scaned_tags",
  scaned_by_swm: "scaned_by_swm",
  scaned_by_mosquito: "scaned_by_mosquito",
  scaned_by_electrical: "scaned_by_electrical",
};
