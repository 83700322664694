import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const RemoveTags = async (  {token,premise_no,}:Record<'token'|'premise_no', string>

): Promise<AxiosResponse<any>>=> {
    let data = {"premise_no":premise_no};
  const config: AxiosRequestConfig = {
    method: "post",
    url: "https://api.sht.distronix.in:4443/api/v1.2/tags/deletePremise",
    headers: {
        'Content-Type': 'application/json',
        'x-access-token':token
    },
   data:data
  };

  const response: AxiosResponse<any> = await axios(config);
  console.log(response.data)
  return response;
};