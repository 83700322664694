import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const UpdateUserPic = async (
  {token,imageFile,}:Record<'token'|'imageFile', any>
 
): Promise<AxiosResponse<any>> => {

    let data = new FormData();
    data.append('file',imageFile);

  var config:AxiosRequestConfig = {
    method: "post",
    url:
      "https://api.sht.distronix.in:4443/api/user/updateProfilePic",
    headers: {
      'x-access-token': token,
    },
     data:data
  };

  const response: AxiosResponse<any> = await axios(config);
  console.log("Add User pic: ", response.data)
  return response;
};