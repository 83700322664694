import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GoogleMapReact from "google-map-react";
import {
  faMapMarker,
  faIcons,
  faArrowRight,
  faMapMarkerAlt,
  faBan,
  faSprayCan,
  faTag,
  faTrash,
  faWifi,
  faShareAlt,
  faTrashAlt,
  faMoneyBill,
  faMoneyBillAlt,
  faBolt,
  faTachometerAlt,
  faBus,
  faShuttleVan,
  faCaravan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateRange, FilterBar, Card } from "../../components";
import Color from "color";
import Styles from "./index.module.css";
import {
  Button,
  Tooltip,
  Typography,
  PopperProps,
  Container,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { nanoid } from "nanoid";
import Popover from "@material-ui/core/Popover";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Card as MaterialCard } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import { DateBox } from "devextreme-react";
import {
  useAppDispatch,
  getAndStoreTags,
  RootState,
  Tag,
  getAndStoreTodaysKPIs,
} from "../../redux";
import useSupercluster from "use-supercluster";
import { BBox } from "geojson";
import { useSelector } from "react-redux";
import { getBinsUsageData, ITagUsageData, KPIs } from "../../api";
import {
  useFilteredMap,
  FilterTags,
} from "../../components/FilterBar/useFilteredMap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Input } from "@material-ui/core";
import { GetWbsedclData } from "../../api/Electricity/getWbsedclData";
import { getKPIs2 } from "../../api/tags/getKPIs2";
import { callAPI } from "../../api/callAPI";
import { GetVtsReport } from "../../api/getVtsReport";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    root: {
      minWidth: 275,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginTop: 10,
      marginBottom: 3,
    },
    capitalize: {
      textTransform: "capitalize",
    },
  })
);

interface IElectricityDataItem {
  address: string;
  consumer_name: string;
  consumer_no: number;
  meter_no: string;
  premise_no: string;
}
interface IVtsData {
  action_area: string;
  block: string;
  createdAt: string;
  id: number;
  last: string;
  plot_no: string;
  premise_no: string;
  updatedAt: string;
  vts_cleaned_cs: string;
}

interface IElectricityData {
  wbsedlc: IElectricityDataItem[];
}

interface IMapMarker extends Tag, ITagUsageData, IElectricityData, IVtsData {
  lat: number;
  lng: number;
  onClick: React.MouseEventHandler<SVGSVGElement>;
}

const tooltipcomponent: React.FC<PopperProps> = (props) => {
  return (
    <div>
      Project: props.project_name
      <br />
      Location: props.latitude, props.longitude
    </div>
  );
};

const ClusterMarker = (props: {
  cleaned: number;
  vts_cleaned: number;
  lat: number;
  lng: number;
  pointCount: number;
  key: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const hueRatio = Math.floor((props.cleaned / props.pointCount) * 120);
  const hueRatio2 = Math.floor((props.vts_cleaned / props.pointCount) * 240);
  const { Service: SelectedService } = useSelector(
    (state: RootState) => state.filterbar
  );

  // console.log = function() {}

  const clusterColor = ()=>{
     
    const flag1 = SelectedService.findIndex((s) => s.key === "swm") !== -1
    const flag2 = SelectedService.findIndex((s) => s.key === "vts") !== -1
 
    
    if(flag1 && flag2){
     return `hsl(${hueRatio2},100%,50%)`
    }
    else if(flag1){
      return `hsl(${hueRatio},100%,50%)`
    }
    else if(flag2){
     return `hsl(${hueRatio2},100%,50%)`
    }
    else{
      return'red'
    }
 
   }

  return (
    <div
      style={{
        height: 30,
        width: 30,
        borderRadius: 15,
        fontSize: 12,
        backgroundColor: clusterColor(),
        borderColor: "white",
        borderWidth: 1,
        padding: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
      }}
      onClick={props.onClick}
    >
      {props.pointCount}
    </div>
  );
};
//@ts-ignore
const MapMarker = (props: IMapMarker): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
  const { Service: SelectedService } = useSelector(
    (state: RootState) => state.filterbar
  );

  const handleOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getServiceIcon = (Service: string, Open: boolean): IconProp => {
    switch (Service) {
      case "swm":
        return Open ? faTrashAlt : faTrash;
      case "mosquito":
        return faSprayCan;
      case "tax":
        return Open ? faMoneyBillAlt : faMoneyBill;
      case "wbsedcl":
        return faTachometerAlt;
      case "vts":
        return faShuttleVan;
      case "tag":
      default:
        return Open ? faMapMarkerAlt : faMapMarker;
    }
  };
  const getMarkerColor = () => {

    let flag1 = SelectedService.findIndex((s) => s.key === "swm") !== -1;
    let flag2 = SelectedService.findIndex((s) => s.key === "vts") !== -1;

    if (flag1 && flag2 && props.isCleanedToday) {
      if (!!props.vts_cleaned_cs && props.vts_cleaned_cs !== " ") {
        return "blue";
      } else {
        return "green";
      }
    } else if (flag1) {
      if (props.isCleanedToday) {
        return "green";
      } else {
        return "red";
      }
    } else if (flag2 && !!props.vts_cleaned_cs) {
      if (props.isCleanedToday && props.vts_cleaned_cs !== " ") {
        return "blue";
      } else if (props.isCleanedToday && props.vts_cleaned_cs === " ") {
        return "green";
      } else {
        return "red";
      }
    } else {
      return "red";
    }
  };


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  console.log('mapmarker:',props)
  return (
    <>
      <Tooltip
        arrow
        title={`Project: ${props.project_name}`}
        //PopperComponent={tooltipcomponent}
        onAnimationStart={(e) => console.log(props)}
        placement="top-end"
      >
        <div aria-describedby={String(id)}>
          <FontAwesomeIcon
            style={{ zIndex: 4 }}
            size={open ? "3x" : "2x"}
            // color={SelectedService.findIndex((s) => s.key === "swm") !== -1? (props.isCleanedToday ? "green" : "red"):
            // SelectedService.findIndex((s) => s.key === "vts") !== -1?(props.vts_cleaned_cs!==' ' ? "blue" : "red"):'red'}
            color={getMarkerColor()}
            icon={getServiceIcon(
              SelectedService.length === 1 ? SelectedService[0].key : "tag",
              open
            )}
            onClick={(e) => {
              handleOpen(e);
              console.log("Props: ", props);
            }}
            onDoubleClick={props.onClick}
            // onMouseEnter={handleOpen}
            // onMouseLeave={() => {
            //   setTimeout(() => {
            //     handleClose();
            //   }, 2500);
            // }}
          />
        </div>
      </Tooltip>
      {open && (
        <Popover
          id={String(id)}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MaterialCard className={classes.root}>
            <CardContent
              style={{ maxWidth: 400, display: "flex", flexDirection: "row" }}
            >
              <Container
                style={{
                  maxWidth: 120,
                  margin: 0,
                  padding: 0,
                  paddingRight: 10,
                }}
              >
                <a href={props.photo_url} target="_blank">
                  <img
                    src={props.photo_url}
                    style={{ maxWidth: 115 }}
                    alt={props.project_name}
                    srcSet={props.photo_url}
                  />
                </a>
              </Container>
              <Container style={{ margin: 0, padding: 0, maxWidth: 280 }}>
                Project Name: <b>{props.project_name}</b>
                <br />
                Tag Location:{" "}
                <b>
                  {props.latitude}, {props.longitude}
                </b>
                <br />
                Action Area: <b>{props.action_area}</b>
                <br />
                Block Number: <b>{props.block}</b>
                <br />
                Plot Number: <b>{props.plot_no}</b>
                <br />
                Premise Number: <b>{props.premise_no}</b>
                <br />
                {/* Last Checked: <b>{moment(props.updatedAt).format("ll")}</b>
                <br /> */}
                {SelectedService.length > 0 &&
                  SelectedService.findIndex((s) => s.key === "swm") !== -1 && (
                    <>
                      Last Cleaned Date:{" "}
                      <b>
                        {props.lastCleanedAt === "null"
                          ? "Data Not Available"
                          : moment(props.lastCleanedAt).format("ll")}
                      </b>
                      <br />
                      Last Cleaned Time:{" "}
                      <b>
                        {props.lastCleanedAt === "null"
                          ? "Data Not Available"
                          : moment(props.lastCleanedAt).format("LT")}
                      </b>
                      <br />
                      Last Cleaned By:{" "}
                      <b>
                        {props.AgencyName === "null"
                          ? "Agency name not available"
                          : props.AgencyName}
                      </b>
                      <br />
                    </>
                  )}
                {SelectedService.length > 0 &&
                  SelectedService.findIndex((s) => s.key === "vts") !== -1 && (
                    <>
                      Vehicle No:{" "}
                      <b>
                        {props.vts_cleaned_cs === " " || !props.vts_cleaned_cs
                          ? "Data Not Available"
                          : props.vts_cleaned_cs}
                      </b>
                      <br />
                 
                    
                    </>
                  )}
                {SelectedService.length > 0 &&
                  SelectedService.findIndex((s) => s.key === "wbsedcl") !==
                    -1 && (
                    <>
                      WBSEDCL:{" "}
                      {!!props.wbsedlc &&
                      typeof props.wbsedlc.length === "number"
                        ? props.wbsedlc.map((j: IElectricityDataItem) => (
                            <div
                              style={{
                                boxShadow:
                                  "0px 10px 19px 3px rgba(164,164,164,0.61)",
                                borderRadius: "20px",
                                textAlign: "center",
                                padding: "7px",
                              }}
                            >
                              <p>
                                {" "}
                                ConsumerName:<b>{j.consumer_name}</b>
                              </p>
                              <p>
                                {" "}
                                ConsumerNo: <b>{j.consumer_no}</b>
                              </p>
                              <p>
                                {" "}
                                MeterNo:<b>{j.meter_no}</b>
                              </p>
                              <p>
                                {" "}
                                Address:<b>{j.address}</b>
                              </p>
                            </div>
                          ))
                        : "Data Not Available"}
                    </>
                  )}
              </Container>
            </CardContent>
          </MaterialCard>
        </Popover>
      )}
    </>
  );
};

export const MapView = () => {
  const [CardsShow, setCardsShow] = useState<boolean>(true);
  const [Zoom, setZoom] = useState<number>(14);
  const [Bounds, setBounds] = useState<BBox>([0, 0, 0, 0]);
  const [ShowMarkers, setShowMarkers] = useState(true);
  const [SelectedDate, setSelectedDate] = useState<moment.Moment>(moment());
  const mapRef = useRef();
  const { Service: SelectedService } = useSelector(
    (state: RootState) => state.filterbar
  );

  const handleToggleMarkers = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowMarkers((state) => !state);
  };

  const center = {
    lat: 22.578,
    lng: 88.4668,
  };
  const [Center, setCenter] = useState(center);
  const zoom = 13;

  const dispatch = useAppDispatch();
  const { tags: total_tags, total } = useSelector(
    (state: RootState) => state.tags
  );
  const { token, refreshToken } = useSelector((state: RootState) => state.user);
  const filters = useSelector((state: RootState) => state.filterbar);

  const [LiveBinsData, setLiveBinsData] = useState<ITagUsageData[]>([]);
  const [vtsData, setVtsData] = useState<IVtsData[]>([]);
  const [ElectricityData, setElectricityData] = useState<any>([]);
  const [DatewiseKpi, setDatewiseKpi] = useState<any>({});
  const { callback: refreshTags } = useFilteredMap();
  const tags = ShowMarkers ? FilterTags(total_tags, filters) : total_tags;
  const { kpi, loading, loaded } = useSelector((state: RootState) => state.kpi);
  const [vtsCleaned,setVtsCleaned] = useState(0)
 
  const points = useMemo(
    () =>
      tags
        .map((tag) => ({
          type: "Feature",
          properties: {
            cluster: false,
            point_count: 1,
            ...tag,
            ...LiveBinsData.find((i) => i.premise_no === tag.premise_no),
            ...ElectricityData.find(
              (i: any) => i.premise_no === tag.premise_no
            ),
            ...vtsData.find((i: any) => i.premise_no === tag.premise_no),
          },
          geometry: {
            type: "Point",
            coordinates: [tag.longitude, tag.latitude],
          },
        }))
        .filter((e) => {
          if (
            SelectedService.length === 1 &&
            SelectedService[0].key === "wbsedcl"
          ) {
            // console.log("Reaching Here flag 2")
            const result =
              !!e?.properties?.wbsedlc &&
              typeof e.properties.wbsedlc.length === "number";
            // console.log("WBSEDCL Data: ", e.properties.wbsedlc, "Result: ", result);
            return result;
          }
          // else if(
          //   SelectedService.length === 1 &&
          //   SelectedService[0].key === "vts"

          // ) {
          //   const result =
          //   !!e?.properties?.vts_cleaned_cs &&
          //   typeof e.properties.vts_cleaned_cs === "string";
          //   return result
          // }
          else return true;
        }),
    [tags, LiveBinsData, ElectricityData, SelectedService]
  );
  // console.log(points);
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: Bounds,
    zoom: Zoom,
    options: {
      radius: 75,
      maxZoom: 20,
      map: (props: any) => ({
        cleaned: props.isCleanedToday?1:0,
        vts_cleaned: !props.vts_cleaned_cs || props.vts_cleaned_cs===" "?0:1,
      }),
      reduce: (acc: any, props: any) => {
        acc.cleaned += props.cleaned;
        acc.vts_cleaned += props.vts_cleaned;
        return acc
      },
    },
  });
  const GetAndStoreTags = useCallback(async () => {
    dispatch<any>(getAndStoreTags());
  }, [dispatch]);

  const GetAndStoreKPIs = useCallback(async () => {
    dispatch<any>(getAndStoreTodaysKPIs());
  }, [dispatch]);

  const GetBinsUsageData = useCallback(
    async (date = moment().format("YYYY-MM-DD")) => {
      // const tags = await getBinsUsageData(date);
      const tags = await callAPI<any>(
        getBinsUsageData,
        { token: token, QueryDate: date },
        refreshToken,
        dispatch
      );

      setLiveBinsData(tags?.data.tags);
      // console.log("Tags map: ",tags?.data.tags)
    },
    [getBinsUsageData]
  );

  const GetWbsedclUsageData = useCallback(async () => {
    const eedata = await callAPI<any>(
      GetWbsedclData,
      { token: token },
      refreshToken,
      dispatch
    );
    setElectricityData(eedata?.data?.data);
    // console.log("electricty: ",eedata?.data.data)
  }, [GetWbsedclData]);

  const GetAndStoreKPIs2 = async (SelectedDat: moment.Moment) => {
    const kpiData = await callAPI<any>(
      getKPIs2,
      { token: token, start_date: SelectedDat.format("YYYY-MM-DD") },
      refreshToken,
      dispatch
    );

    setDatewiseKpi(kpiData?.data.kpi);
    // console.log('new kpi:',kpiData)
  };
  const GetVtsData = async (SelectedDat: moment.Moment) => {
    const vts = await callAPI<any>(
      GetVtsReport,
      {
        token: token,
        startDate: SelectedDat.format("YYYY-MM-DD"),
        endDate: SelectedDat.format("YYYY-MM-DD"),
      },
      refreshToken,
      dispatch
    );

    let j =0;
    setVtsData(vts?.data.data);
    vts?.data.data.forEach((i:IVtsData)=> {
      
      i.vts_cleaned_cs !== ' '&& j++    
  })

    setVtsCleaned(j)
    // console.log('vts data:',newData)
  };

  useEffect(() => {
    GetAndStoreTags();
    GetAndStoreKPIs();
    GetBinsUsageData();
    GetWbsedclUsageData();
  }, []);

  useEffect(() => {
    GetAndStoreKPIs2(SelectedDate);
    GetVtsData(SelectedDate);
  }, [SelectedDate]);

  // console.log(tags)
  return (
    <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
      <div className={Styles.DateRangePicker}>
        <DateBox
          value={SelectedDate.format("YYYY-MM-DD")}
          onValueChange={(value) => {
            setSelectedDate(moment(value, "YYYY-MM-DD"));
            GetBinsUsageData(value);
          }}
        />
      </div>
      <FormControlLabel
        className={Styles.ToggleButton}
        value="top"
        control={
          <Switch
            color="primary"
            onClick={() => setCardsShow((state) => !state)}
          />
        }
        label={!CardsShow ? "Hide KPI Cards" : "Show KPI Cards"}
        labelPlacement="top"
      />
      <FilterBar
        refreshHook={refreshTags}
        toggleMarkers={handleToggleMarkers}
      />
      <div className={Styles.CardContainer}>
        <Card
          Icon={faWifi}
          Mask={faTag}
          Value={loading && !loaded ? 0 : DatewiseKpi.total_tags}
          Title="Total Tags"
          Description="The total number of deployed cards which are scanned today."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#e1be24", "hex")}
          style={{
            visibility: !CardsShow ? "visible" : "hidden",
            maxHeight: 150,
          }}
        />
        {filters.Service.findIndex((s) => s.key === "vts") !== -1 && (
          <Card
            Icon={faShuttleVan}
            Mask={faTag}
            Value={loading && !loaded ? 0 : vtsCleaned}
            Title="VTS Bin Check"
            Description="The total number of deployed cards which are checked by VTS today."
            FontColor={Color.rgb(255, 255, 255)}
            BackgroundColor={Color("#4d41ba", "hex")}
            style={{
              visibility: !CardsShow ? "visible" : "hidden",
              maxHeight: 150,
            }}
          />
        )}
        <Card
          Icon={faTrash}
          Value={loading && !loaded ? 0 : DatewiseKpi.total_cleaned}
          Title="Total Bins Cleaned Today"
          Description="The total number of deployed cards scanned by the solid waste cleaning agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#36bea6", "hex")}
          style={{
            visibility: !CardsShow ? "visible" : "hidden",
            maxHeight: 150,
          }}
        />
        <Card
          Icon={faBan}
          Mask={faTrash}
          Value={loading && !loaded ? 0 : DatewiseKpi.total_uncleaned}
          Title="Total Tags not Scanned"
          Description="The total number of deployed cards not scanned by the solid waste cleaning agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#e61f26", "hex")}
          style={{
            visibility: !CardsShow ? "visible" : "hidden",
            maxHeight: 150,
          }}
        />
        {/* <Card
          Icon={faSprayCan}
          Value={113}
          Title="Scanned by Mosquito Service"
          Description="The total number of deployed cards not scanned by the mosquito repellent agencies."
          FontColor={Color.rgb(255, 255, 255)}
          BackgroundColor={Color("#A31EF5", "hex")}
          style={{
            visibility: CardsShow ? "visible" : "hidden",
            transform: "scale(1)",
          }}
        /> */}
      </div>

      <div style={{ flex: 1 }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyBEXQ990KC3e-1GklhfLENNavc40uRLvKk",
          }}
          defaultCenter={center}
          center={Center}
          defaultZoom={zoom}
          options={{
            mapTypeControl: true,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
        >
          {clusters.map((cluster) => {
            // console.log('cluster-->',cluster)
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
              cleaned,
              vts_cleaned
            } = cluster.properties;
            if (isCluster) {
              return (
                //@ts-ignore
                <ClusterMarker
                  cleaned={cleaned}
                  vts_cleaned={vts_cleaned}
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                  pointCount={pointCount}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );

                    if (typeof mapRef !== "undefined") {
                      //@ts-ignore
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }
                  }}
                />
              );
            }

            return (
              <MapMarker
                {...cluster.properties}
                lat={latitude}
                lng={longitude}
                key={nanoid()}
                onClick={(e) => {
                  if (typeof mapRef !== "undefined") {
                    setCenter({
                      lat: parseFloat(latitude),
                      lng: parseFloat(longitude),
                    });
                  }
                }}
              />
            );
          })}
          {/* {total > 0 &&
            tags
              .slice(0, 10)
              .map((t) => (
                
              ))} */}
        </GoogleMapReact>
      </div>
    </div>
  );
};
