import { useEffect, useState } from 'react'
import { store, Tag, FilterBarState } from '../../redux'

export const useFilteredMap = () => {
  const [Tags, setTags] = useState(store.getState().tags.tags);
  const callback = (refresh: boolean) => {
    console.log("Executing callback inside useFilterMap");
    const newTags = refresh ? FilterTags(Tags, store.getState().filterbar) : Tags;
    setTags(newTags);
    }
  
  return {Tags, callback};
}

export const FilterTags = (Tags: Tag[], Filters: FilterBarState): Tag[] => {
    console.log("executing FilterTags from useFilteredMap")
  let filteredtags: Tag[] = Tags;
  

    if(Filters.ActionArea.length>0){
        filteredtags = Tags.filter((tag) => {
      if (Filters.ActionArea.some((aa) => aa.value === tag.action_area))
        return true;
        });
    }
    if (Filters.Block.length > 0) {
        filteredtags = Tags.filter((tag) => {
          if (Filters.Block.some((block) => block.block === tag.block))
            return true;
        });
    }
    if (Filters.Premise.length > 0) {
        filteredtags = Tags.filter((tag) => {
          if (Filters.Premise.some((premise) => premise === tag.premise_no))
            return true;
        });
    }
    if (Filters.Plot.length > 0) {
      filteredtags = Tags.filter((tag) => {
        if (Filters.Plot.some((plot) => plot.plot === tag.plot_no && plot.block === tag.block))
          return true;
      });
  }
  
  if (Filters.Query.length > 0) {
    filteredtags = filteredtags.filter((i) => JSON.stringify(i).search(new RegExp(Filters.Query, 'i')) >= 0)
  }
    return filteredtags;
}