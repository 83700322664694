import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as types from "../../index";
import { getAllTags, getDataVersion, type_getDataVersion } from "../../../api";
import { store } from "../../index";
import { callAPI } from "../../../api/callAPI";

export const getTagsStaticData = (
  tags: types.Tag[]
): types.FilterBarStaticData => {
  const StaticData: types.FilterBarStaticData = {
    ActionArea: [],
  };
  const ActionAreas = tags
    .map((t) => t.action_area)
    .filter((item, i, ar) => ar.indexOf(item) === i);
  const Blocks = tags.map((t) => {
    return { b: t.block, aa: t.action_area };
  });
  ActionAreas.forEach((aa) => {
    StaticData.ActionArea.push({
      name: aa,
      Block: Blocks.filter((b) => b.aa === aa)
        .map((b) => b.b)
        .filter((item, i, ar) => ar.indexOf(item) === i)
        .map((b) => {
          return {
            name: b,
            tags: tags
              .filter((t) => t.action_area === aa && t.block === b)
              .map(
                (
                  t
                ): {
                  plot: string;
                  premise: string;
                } => {
                  return {
                    plot: t.plot_no,
                    premise: t.premise_no,
                  };
                }
              ),
          };
        }),
    });
  });
  return StaticData;
};

export const getAndStoreTags = createAsyncThunk(
  "tags/getAndStoreTags",
  async (thunkAPI): Promise<types.TagState> => {
    const tags_available_version: string = store.getState().tags.version;
    const user = store.getState().user;
    const latestDataVersionResponse = await callAPI<type_getDataVersion>(
      getDataVersion,
      { token: user.token },
      user.refreshToken,
      store.dispatch
    );

    const tags_latest_version: string | undefined = latestDataVersionResponse?.data?.version.find((t) => t.table_name === "tag")?.version_no;
    const new_tags_not_available =
      tags_available_version === tags_latest_version;
    if (new_tags_not_available) return store.getState().tags;
    console.log("getAndStoreTags function just before callAPI");


    const tags = await callAPI<types.TagState>(
      getAllTags,
      { token: user.token },
      user.refreshToken,
      store.dispatch
    );

    
    console.log(
      "Response from callAPI in getAndStoreTags function: ",
      tags?.data
    );
    const response: types.TagState =
      tags === null ? store.getState().tags : tags.data;
    const version = !!tags_latest_version
      ? tags_latest_version
      : tags_available_version;
    console.log("Version: ", version);
    return { ...response, version: version };
  }
);

const initialState: types.TagState = {
  version: "0.0",
  total: 0,
  tags: [],
  StaticData: {
    ActionArea: [],
  },
};

export const TagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    replace_all: (
      state: types.TagState,
      action: PayloadAction<types.TagState>
    ): types.TagState => {
      return action.payload;
    },
  },
  extraReducers: {
    [getAndStoreTags.fulfilled.type]: (
      state: types.TagState,
      action: PayloadAction<types.TagState>
    ) => {
      state.version = action.payload.version;
      state.tags = action.payload.tags;
      state.total = action.payload.total;
      state.StaticData = getTagsStaticData(action.payload.tags);
    },
  },
});

export {};
