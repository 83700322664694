import { configureStore, ThunkAction, Action, combineReducers, EnhancedStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { UserSlice, FlagSlice, RootState, TagSlice, KPISlice } from '../index';
import { FilterBarSlice } from '../features';

const persistConfig = {
  key: "root",
  storage,
  // blacklist: ['user']
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    flags: FlagSlice.reducer,
    filterbar: FilterBarSlice.reducer,
    kpi: KPISlice.reducer,
    tags: TagSlice.reducer,
    user: UserSlice.reducer,
  })
);

export const store: EnhancedStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), // .concat(logger),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;

  export type AppDispatch = typeof store.dispatch;
  export const useAppDispatch = () => useDispatch<AppDispatch>();