

import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import useStyles from './styles';

import { callAPI } from '../../api/callAPI';
import { AddUserApi } from '../../api/Account/addUser';
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux';
import { AnyCnameRecord } from 'node:dns';
import { Col, Form } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { useSnackbar } from "notistack";
const AddUser: React.FC<{ closeView: () => void }> = ({
  closeView = () => {},
})=> {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();
    const [postData, setPostData] = useState({
      userName: "",
      type: "",
      password: "",
      confirmPassword: "",
      name:"",
    });

    const dispatch  = useAppDispatch()
    const {token,refreshToken,userRole}  = useSelector((state:RootState) => state.user)
    const clear = () => {
      setPostData({ 
      userName: "",
      type: "",
      password: "",
      confirmPassword: "",
      name: ""});
    };

    const handleSubmit = async (e: any) => {
      e.preventDefault();
      const data = await callAPI<any>(
        AddUserApi,
        { token: token,
          userName: postData.userName,
          type: postData.type,
          password: postData.password,
          confirmPassword: postData.confirmPassword,
          name: postData.name
          },
        refreshToken,
        dispatch
      );
      if(data?.data?.status === 'success'){
        enqueueSnackbar("New User Addded Successfully", { variant: "success" });
      }
      else{
        enqueueSnackbar("Failed To Add New User", { variant: "error" });
      }

      closeView()
      console.log('add user-->',data)
    };
    
    return (
      
         
      <>
      <div style={{ marginLeft: "30px", display: "flex" }}>
        <Form>
          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
          <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                User Name
              </Form.Label>
              <Form.Control
                style={{ width: 250,marginRight: "20px"  }}
                placeholder="User Name"
                value={postData.userName}
                onChange={(e) => setPostData({...postData,userName:e.target.value})}
              />
            </Form.Group>
            
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Type Of User
              </Form.Label>
              <Form.Control
                style={{ borderWidth: 1, borderColor: "lightgrey", width: 250 }}
                as="select"
                placeholder="Type Of User"
                value={postData.type}
                onChange={(e) => setPostData({...postData, type:e.target.value})}
              >
                <option disabled value="">
                  Please Select
                </option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </Form.Control>
            </Form.Group>
   
          </Form.Row>

          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
            
          <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Name
              </Form.Label>
              <Form.Control
                style={{ width: 250 }}
                placeholder="Name"
                value={postData.name}
                onChange={(e) => setPostData({...postData,name:e.target.value})}
              />
            </Form.Group>
          

          </Form.Row>

          <Form.Row style={{ display: "flex", marginTop: "10px" }}>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Password
              </Form.Label>
              <Form.Control
                type='password'
                style={{ width: 250 }}
                placeholder="Password"
                value={postData.password}
                onChange={(e) => setPostData({...postData,password:e.target.value})}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label
                style={{
                  fontSize: "16",
                  color: "grey",
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Confirm Password
              </Form.Label>
              <Form.Control
                type='password'
                style={{ width: 250 }}
                placeholder="Confirm Password"
                value={postData.confirmPassword}
                onChange={(e) => setPostData({...postData,confirmPassword:e.target.value})}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
      {/* <label htmlFor="image" style={{marginLeft:'10%'}}>Add Tag Image</label>
      <input
        accept="image/*"
        id="image"
        name="image"
        type="file"
        onChange={handleChange}
        style={{ marginTop: "20px", marginLeft: "3%" }}
      /> */}

         <div style={{marginTop:'5%',marginLeft:'20%'}}>
          <Button
            className={classes.buttonSubmit}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            onClick={handleSubmit}
            disabled={userRole!=='admin'?true:false}
          >
            Add & Close
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={clear}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            style={{marginLeft:'10px'}}
            size="small"
            onClick={() => closeView()}
          >
            Close
          </Button>
          </div>
          </>
       
    );
}

export default AddUser
