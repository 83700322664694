import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const UpdateUserApi = async (
  {token,userName,password,name}:Record<'token'|'userName'|'password'|'name', string>
 
): Promise<AxiosResponse<any>> => {

  let data = {
    'userName': userName,
   'password': password,
   'name':name
   };

  var config:AxiosRequestConfig = {
    method: "post",
    url:
      "https://api.sht.distronix.in:4443/api/user/updateProfile",
    headers: {
      'x-access-token': token ,
      'Content-Type': 'application/json'
    },
     data:data
  };

  // const result2 = ApiHandler({ config: config, retry: 0 })
  // const result = await axios(config)
  //   .then((res) => ({ data: res.data, success: true, error: "" }))
  //   .catch((e) => ({ error: e, success: false, data: [] }));
  // console.log(result);
  // return result;
  const response: AxiosResponse<any> = await axios(config);
  console.log("Add User: ", response.data)
  return response;
};
