import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import * as types from '../../redux';

export const getAllTags = async ({token}:Record<'token', string>): Promise<AxiosResponse<types.TagState>> => {
  //This function needs to be implemented after the API is provided.
    // const response = await axios[EndPoints['getAllTags'].type](EndPoints['getAllTags'].uri);
    // return response.data;

  const config: AxiosRequestConfig = {
    method: "get",
    url: "https://api.sht.distronix.in:4443/api/v1.2/tags/get_all",
    headers: {
      "x-access-token": token,
    },
  };
  const response: AxiosResponse<types.TagState> = await axios(config);
  console.log("GetAllTags: ", response.data)
  return response;
};
