export interface IEndPoint {
    type: 'get' | 'post' | 'put' | 'patch' | 'delete';
    uri: string;
    /**if true, auth needed; else public endpoint */
  auth: boolean;
  haveParams: boolean;
}

export const base: string = "http://35.200.133.32:5000/api/";

export const EndPoints: { [name: string]: IEndPoint } = {
  getAllTags: {
    type: "get",
    uri: base + "v1.2/tags/get_all",
    auth: false,
    haveParams: false,
  },
  getKPIs: {
    type: "get",
    uri: base + "v1.1/statistics/kpi",
    auth: false,
    haveParams: true,
  },
  GetBinsDataActionAreaBlockWiseToday: {
    type: "get",
    uri: base + "v1.1/statistics/bins",
    auth: false,
    haveParams: true,
  },
  GetTagScansAgencyWise: {
    type: "post",
    uri: base + "v1.1/statistics/TagScanedByAgency",
    auth: false,
    haveParams: true,
  },
};